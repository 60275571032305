import axios from 'axios';

export default class Docurain {
  // TODO: 定義ファイル・マスタに移動
  private token = 'token LOm3yvVnKO1KOZkGQztVPf0ghPEchPu9Mz9msmYE';
  private createFormConfig = {
    headers: {
      'content-type': 'application/json',
      Authorization: this.token
    }
  };

  async createForm(
    template: any,
    data: any,
    format: string,
    header?: { [k: string]: { [k: string]: string } }
  ) {
    if (header === undefined || header === null) header = this.createFormConfig;
    const config = JSON.parse(JSON.stringify(header));
    config['responseType'] = 'arraybuffer';
    // https://api.docurain.jp/api/[*出力形式]/[テンプレート名]
    return await axios.post(
      `https://api.docurain.jp/api/${format}/${template}`,
      JSON.stringify(data),
      config
    );
  }
}
