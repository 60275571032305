import axios from 'axios';
import { ApiData, ApiResult } from '@/services/hexabase/types';
const defaultConfig = {
  headers: {
    'content-type': 'application/json'
  }
};

/**
 * Hexabase API呼び出し実装クラス
 */
export default class HexabaseApi {
  private apiDomain = '';
  constructor() {
    // this.apiDomain = '/linker-api';
    this.apiDomain = 'https://api.hexabase.com/api/v0';
  }

  /**
   * Hexabaseバックエンドにログインする
   *
   * @param email Hexabaseに登録したメールアドレス
   * @param password パスワード
   * @returns Hexabaseのtoken文字列
   */
  public async login(email: string, password: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    const params = {
      email: email,
      password: password
    };

    const result = await axios.post(`${this.apiDomain}/login`, params, config);
    return result.data.token as string;
  }

  /**
   * Hexabaseバックエンドからログアウトする
   *
   * @param token Hexabaseのtoken
   * @returns true: 処理成功
   */
  public async logout(token: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.post(`${this.apiDomain}/users/logout`, {}, config);
    return result.status == 200;
  }

  /**
   * tokenで指定されたユーザーに関連した情報を取得する
   * user_rolesには、ユーザーが保有するロールの一覧が返る
   * user_groupsには、現在のワークスペース内でユーザーが所属するグループの一覧が返る
   *
   * @param token Hexabaseのtoken
   * @returns 下記サンプル参照
   * {
   *   "u_id": "現在のユーザ",
   *   "username": "ユーザー名",
   *   "email": "現在のユーザーのemailアドレス",
   *   "profile_pic": "ユーザーのプロファイル画像の保存先",
   *   "current_workspace_id": "現在使用しているワークスペースのID",
   *   "is_ws_admin": true,   //(bool このユーザーにワークスペースのアドミン権限が付与されているかどうか, trueはアドミン権限有り)
   *   "user_roles": [
   *     {
   *       "r_id": "システム内部のロールID",
   *       "role_name": "ロール名ID１",
   *       "role_id": "画面で入力されたロールID",
   *       "p_id": "プロジェクトID1",
   *       "application_id": "アプリケーションID",
   *       "application_name": "アプリケーション名",
   *       "application_display_order": 0
   *     },
   *     {
   *       "r_id": "5e3ac99c393da500077068b0",
   *       "role_name": "部長",
   *       "role_id": "Manager1",
   *       "p_id": "5e015f03285ab60007442e5e",
   *       "application_id": "xxSystem",
   *       "application_name": "バツバツシステム",
   *       "application_display_order": 0
   *     }
   *   ],
   *   "user_groups": [
   *     {
   *       "g_id": "5c5fd6c084f4be2574e2bcaf",
   *       "group_name": "営業１課",
   *       "group_id": "1101"
   *     },
   *     {
   *       "g_id": "5c5fd6c084f4be2574e2bcb0",
   *       "group_name": "A部",
   *       "group_id": "1001"
   *     },
   *     {
   *       "g_id": "5c5fa7da84f4be4250aaee28",
   *       "group_name": "全社",
   *       "group_id": "1000"
   *     }
   *   ]
   * }
   */
  public async getUserInfo(token: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(`${this.apiDomain}/userinfo`, config);
    return result;
  }

  /**
   * ワークスペースの一覧を取得
   * ログインユーザーが利用可能なワークスペースの一覧を取得
   *
   * 主な用途は、ログイン後に実行し、一覧の中からプロジェクトで扱う特定のワークスペースを決定するといったもの
   * ワークスペース決定後、紐づくアプリケーションIDやデータストアIDを取得する処理が別に続く
   *
   * 原則1プロジェクト1ワークスペース利用となるが、対象のワークスペースが複数あることを考慮し、
   * プロジェクト環境変数としてワークスペース名（workspace_name）を保持、workspace_nameからworkspace_idを特定する
   * ワークスペースIDを特定しておくことで、他APIコール時に指定するworkspace_id値として利用できる
   *
   * @param token Hexabaseのtoken
   * @returns 下記サンプル参照
   * {
   *   "workspaces": [
   *     {
   *       "workspace_id": "582b26d7fb90a15e0c24ad80",
   *       "workspace_name": "Testワークスペース"
   *     },
   *     {
   *       "workspace_id": "58dfcd20fbfcba39c881021e",
   *       "workspace_name": "ABC株式会社"
   *     },
   *     {
   *       "workspace_id": "58ca3597cce5fe3ea0a42fa8",
   *       "workspace_name": "XXXX部門"
   *     },
   *     {
   *       "workspace_id": "58ca3211cce5fe2e84446cd3",
   *       "workspace_name": "○○○プロジェクト"
   *     },
   *   ]
   * }
   */
  async getWorkspaces(token: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(`${this.apiDomain}/workspaces`, config);
    return result.data.workspaces;
  }

  /**
   * ワークスペースに紐づくアプリケーションの一覧を取得
   *
   * 原則1プロジェクトに1アプリケーションとなる
   * アプリケーションIDを特定しておくことで、
   * 他APIコール時に指定するapplication_id値として利用できる
   *
   * @param token Hexabaseのtoken
   * @param workspaceId 対象のワークスペースID
   * @returns 下記サンプル参照
   * [
   *   {
   *     "application_id": "59bf424c0e247918255de008",
   *     "name": "アプリケーション1",
   *     "display_id": "App1",
   *     "datastores": [
   *       {
   *           "datastore_id": "59bf42550e2479186a6c6c70",
   *           "name": "データストア1"
   *       }
   *     ]
   *   },
   *   {
   *     "application_id": "59bf424c0e247918255de008",
   *     "name": "アプリケーション2",
   *     "display_id": "App2",
   *     "datastores": [
   *       {
   *         "datastore_id": "59bf42550e2479186a6c6c70",
   *         "name": "データストア2"
   *       },
   *       {
   *         "datastore_id": "59bf42550e2479186a6c6c70",
   *         "name": "データストア3"
   *       }
   *     ]
   *   }
   * ]
   */
  async getApplications(token: string, workspaceId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/workspaces/${workspaceId}/applications`,
      config
    );
    return result.data;
  }

  /**
   * データストアに紐づくフィールド（テーブルの項目）の一覧を取得
   * 利用可能なフィールドの一覧を取得する（DisplayIDを利用）
   * フィールドのdataType(Hexabaseで定義した項目の種類)に依存して、取得されるパラメータが異なる
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @returns 下記サンプル参照
   * {
   * "fields": {
   *   "59bf42550e247918255de00d": {
   *     "field_id": "59bf42550e247918255de00d",
   *     "name": "タイトル",
   *     "display_id": "タイトル",
   *     "dataType": "text",
   *     "search": true,
   *     "show_list": false,
   *     "as_title": true,
   *     "status": false,
   *     "fieldIndex": 0,
   *     "title_order": 1,
   *     "full_text": false,
   *     "unique": false,
   *     "min_value": "",
   *     "max_value": ""
   *   },
   *   "59bf42550e247918255de00e": {
   *     "field_id": "59bf42550e247918255de00e",
   *     "name": "ステータス",
   *     "display_id": "Fld-nj1ZetGV",
   *     "dataType": "status",
   *     "search": true,
   *     "show_list": true,
   *     "as_title": false,
   *     "status": true,
   *     "fieldIndex": 1,
   *     "title_order": 0,
   *     "full_text": false,
   *     "unique": false,
   *     "min_value": "",
   *     "max_value": ""
   *   },
   *   "5a26722e0e24794c979fa5b6": {
   *     "field_id": "5a26722e0e24794c979fa5b6",
   *     "name": "n1",
   *     "display_id": "Fld-BCgyvxUw",
   *     "dataType": "number",
   *     "search": true,
   *     "show_list": true,
   *     "as_title": false,
   *     "status": false,
   *     "fieldIndex": 0,
   *     "title_order": 0,
   *     "full_text": false,
   *     "unique": false,
   *     "min_value": "1",
   *     "max_value": "10"
   *   },
   *   "5a82bffd0e247948bd5cfaf2": {
   *     "field_id": "5a82bffd0e247948bd5cfaf2",
   *     "name": "ds1",
   *     "display_id": "Fld-qFRXJAF2",
   *     "dataType": "dslookup",
   *     "search": false,
   *     "show_list": true,
   *     "as_title": false,
   *     "status": false,
   *     "fieldIndex": 0,
   *     "title_order": 0,
   *     "full_text": false,
   *     "unique": false,
   *     "min_value": "",
   *     "max_value": "",
   *     "dslookup_info": {
   *       "dslookup_datastore_id": "5a82bfd50e247948dba5ad20",
   *       "dslookup_field_id": "ae47af42-3740-4840-a395-b1b5cc4a8b72"
   *     }
   *   },
   *   "5a8392390e247948bd5cfaf3": {
   *     "field_id": "5a8392390e247948bd5cfaf3",
   *     "name": "s1",
   *     "display_id": "Fld-FZR9lYNR",
   *     "dataType": "select",
   *     "search": true,
   *     "show_list": true,
   *     "as_title": false,
   *     "status": false,
   *     "fieldIndex": 0,
   *     "title_order": 0,
   *     "full_text": false,
   *     "unique": false,
   *     "min_value": "",
   *     "max_value": "",
   *     "options": [
   *       {
   *         "option_id": "dc236a0d-58b0-400e-88c7-9276207438cb",
   *         "sort_id": 0,
   *         "value": "選択1",
   *         "enabled": true
   *       },
   *       {
   *         "option_id": "a355531b-c80f-40e8-b6d7-3dc75cc9f3c5",
   *         "sort_id": 1,
   *         "value": "選択2",
   *         "enabled": true
   *       }
   *     ]
   *   }
   * },
   * "field_layout": {
   *   "59bf42550e247918255de00d": {
   *       "field_id": "59bf42550e247918255de00d",
   *       "sizeX": 9,
   *       "sizeY": 1,
   *       "col": 0,
   *       "row": 0
   *   },
   *   "5a26722e0e24794c979fa5b6": {
   *       "field_id": "5a26722e0e24794c979fa5b6",
   *       "sizeX": 6,
   *       "sizeY": 1,
   *       "col": 0,
   *       "row": 1
   *   },
   * }
   *}
   */
  async getFields(token: string, applicationId: string, datastoreId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/fields`,
      config
    );
    return result.data.fields;
  }

  /**
   * データストアに紐づくアイテムの一覧を取得
   * 検索条件を指定することも可能（SQLのような複雑な検索条件は指定できない）
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param params 下記パラメータを指定可能
   * conditions          : 検索条件を指定
   * per_page            : 検索結果の件数(省略、または、0を指定すると、全件取得されます）
   * page                : ページ数
   * sort_field_id       : ソートするフィールドIDを指定(ソートキーが1fieldのみの場合)
   * sort_order          : 昇順の場合"asc" 降順の場合"desc"(ソートキーが1fieldのみの場合)
   * sort_fields         : ソートキーが複数ある場合に指定します。 sort_field_idに優先してソートに利用されます。 [{id: "FIELD_A", order: "asc"},{id: "FIELD_B", order: "desc"}]
   *                       idにフィールド画面ID、orderにソート順を指定します。orderを省略できます。省略すると昇順となります。
   *                       配列で指定した順番で第1ソートキー、第2ソートキーという形で適用されます。
   * use_default_search  : true or false デフォルト検索条件(注)を適用する場合、trueを指定
   * include_links       : true をしていすると、関連するアイテムのIDの配列を取得できます
   * format              : "csv"を指定すると、結果をCSV形式で出力されます
   * ----------------------------------------------------------------------------------------------------
   * （説明）conditions パラメータの指定について
   *
   * 日付型、時刻型、数値型フィールドの場合、 search_value の一番目の値がFrom、2番目の値がToを意味します。
   * どちらかにnullを指定すると、From～、To～といった検索が可能となります。
   * 日付型の場合、値に "TODAY"という文字列を入れると、本日～といった検索が可能です。
   * テキスト型または複数行テキストの場合、search_valueを"|"で区切ると、OR検索が可能です。
   * テキスト型または複数行テキストの場合、"exact_match": trueとした場合、serach_valueの配列に複数条件を指定するとOR検索となります。
   * "exact_match": true, // 完全一致で検索
   * "search_value": [
   *   "ABC|DEF",
   *   "GHI",
   *   "JKL", // ABC or DEF or GHI or JKLを検索
   * ]
   * テキスト型または複数行テキストの場合、"exact_match": falseとした場合、serach_valueの1番目の値に対して正規表現が利用可能です。例えば以下のような指定でOR検索が可能です。正規表現言語としてPCRE（Perl互換正規表現）に従います。
   * "exact_match": false,
   * "search_value": [
   *   "ABC|DEF", // ABC または DEFを検索
   * ]
   * ----------------------------------------------------------------------------------------------------
   * （conditions指定含むparamsサンプル）
   * {
   * "conditions": [
   *   {
   *     "id": "FIELD_ID", // Hexabase画面で入力したIDを指定
   *     "search_value": [
   *       "fa"
   *     ],
   *     "exact_match": true, // 完全一致で検索
   *     "include_null": true // 空白を含む
   *   },{
   *     "id": "FIELD_ID2",
   *     "search_value": [
   *       "value"
   *     ],
   *     "exact_match": false, // 部分一致で検索
   *     "include_null": false // 空白を除く
   *   },{
   *     "id": "i_id", // idに "i_id" を指定すると、指定したItemを対象にできます
   *     "search_value": [
   *       "58272f4efb90a148d850qwer", // item_id
   *       "5846636efb90a1024d29as12", // item_id
   *       "5846636efb90a1024d29asdf"  // item_id (複数件を指定可能)(OR条件)
   *     ]
   *   },{
   *     "id": "updated_by", // idに "updated_by" または "created_by" を指定すると、指定したユーザー(u_id)が更新、作成したItemを検索可能です。
   *     "search_value": [
   *       "58272f4efb90a148d850qwer", // user_id
   *       "5846636efb90a1024d29as12", // user_id
   *       "5846636efb90a1024d29asdf"  // user_id (複数件をOR指定可能)(OR条件)
   *     ]
   *   },{
   *     "id": "updated_at", // idに "updated_at" または "created_at" を指定すると、更新日・作成日での範囲検索が可能です。
   *     "search_value": [
   *       "2020-01-24T10:42:07.880Z",
   *       null,                       // 指定日付以後の更新されたItemを指定
   *     ]
   *   },{
   *     "id": "58bbaa27fbfcba773851339f", // 日付型フィールドでは "TODAY"キーワードを指定可能
   *     "search_value": [
   *       "TODAY",
   *       null     //本日以降のItemを対象とする
   *     ],
   *   },{
   *     "id": "58bbaa27fbfcba773851339f", // 数値型
   *     "search_value": [
   *       null,
   *       "100"  // 100以下の値を検索 ※数値も""で括って指定する
   *     ],
   *   },{
   *     "id": "5e5f334e8250710006078dbd", // StatusID のフィールドID
   *     "search_value": [
   *       "5e5f334e8250710006078dc0"   // status_id
   *     ]
   *   }
   * ],
   * "page": 1,
   * "per_page": 100,
   * "sort_fields": [
   *      {"id": "Status", "order": "desc"}, // 第１ソートキー id:は画面Dを指定
   *      {"id": "Category", "order": "asc"} // 第２ソートキー ascの場合,orderは省略できます
   * ],
   * "sort_field_id": "FIELD_ID", // 画面Dを指定。ソートキーが1つの場合に利用する。sort_fieldsが指定されている場合は、この指定は無視されます
   * "sort_order": "asc",
   * "include_links" : true, // true or false.  trueの場合、取得結果に各Itemに関連するi_idの配列を含める
   * }
   * ----------------------------------------------------------------------------------------------------
   * @returns 下記サンプル参照
   * {
   *   "items": [
   *       {
   *           "58bbaa27fbfcba773851339f": "fadfa",
   *           "58bbaa27fbfcba77385133a2": "新規",
   *           "_id": "596e2327fbfcbab8283dde09",
   *           "a_id": "58bbaa27fbfcba77385133ac",
   *           "access_keys": "",
   *           "created_at": "2017-07-19T00:03:06+09:00",
   *           "created_by": "58272f4efb90a148d8508d9c",
   *           "d_id": "58bbaa27fbfcba6098746061",
   *           "i_id": "596e2327fbfcbab8283dde09",
   *           "p_id": "586f6c2ccce5fe6ad071b1d4",
   *           "rev_no": "1",
   *           "status_id": "58bbaa27fbfcba77385133a4",
   *           "title": "fadfa",
   *           "unread": "0"
   *       },
   *       {
   *           "58bbaa27fbfcba773851339f": "ｆｈｓｆｇｓｄｆｇdadfa",
   *           "58bbaa27fbfcba77385133a2": "受付",
   *           "_id": "594255b8cce5fe234076d7d4",
   *           "a_id": "58bbaa27fbfcba77385133ac",
   *           "access_keys": "",
   *           "created_at": "2017-06-15T18:39:08+09:00",
   *           "created_by": "58272f4efb90a148d8508d9c",
   *           "d_id": "58bbaa27fbfcba6098746061",
   *           "i_id": "594255b8cce5fe234076d7d4",
   *           "p_id": "586f6c2ccce5fe6ad071b1d4",
   *           "rev_no": "2",
   *           "status_id": "58bbaa27fbfcba77385133a7",
   *           "title": "ｆｈｓｆｇｓｄｆｇdadfa",
   *           "unread": "0",
   *           "updated_at": "2017-07-19T00:02:57+09:00",
   *           "updated_by": "58272f4efb90a148d8508d9c"
   *       }
   *   ],
   *   "totalItems": 2
   * }
   */
  async getItems(token: string, applicationId: string, datastoreId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/search`,
      params,
      config
    );
    return result.data;
  }

  /**
   * 検索結果の件数を取得
   * ※上記getItemsで返されるtotalItemsのみを取得する際に使用
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param params 上記getItemsの同paramsを参照
   * @returns x: number
   */
  async getItemCount(token: string, applicationId: string, datastoreId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/search`,
      params,
      config
    );
    return result.data.totalItems;
  }

  /**
   * アイテムの詳細を取得
   *
   * 指定したアイテムの情報（フィールド、ステータス、アクションなど）を取得する
   *
   * @param token Hexabaseのtoken
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @returns 下記サンプル参照
   * "field_values": [
   *     {
   *         "field_id": "Fld-cx4MBjkE",
   *         "field_name": "ファイル２",
   *         "dataType": "file",
   *         "value": {
   *             "_id": "58ce52a5fb90a1429b958025",
   *             "contentType": "text/plain; charset=utf-8",
   *             "created_at": "2017-03-19T18:43:01.336+09:00",
   *             "d_id": "58cbf6cbfbfcba78dc71228d",
   *             "datastore_id": "58cbf6cbfbfcba78dc71228d",
   *             "deleted": false,
   *             "field_id": "58cc9253fbfcba88307142d1",
   *             "file_id": "58ce52a5fb90a1429b958025",
   *             "filename": "win7_Excelで作成.csv",
   *             "filepath": "58cbf6cbfbfcba78dc71228d/58cd1e5bfbfcba2ebcaf0b1e/58cc9253fbfcba88307142d1/win7_Excelで作成.csv",
   *             "i_id": "58cd1e5bfbfcba2ebcaf0b1e",
   *             "item_id": "58cd1e5bfbfcba2ebcaf0b1e",
   *             "mediaLink": "/download_storage/storage/v1/b/attachment-files-test/o/58cbf6cbfbfcba78dc71228d/58cd1e5bfbfcba2ebcaf0b1e/58cc9253fbfcba88307142d1/win7_Excelで作成.csv",
   *             "name": "58cbf6cbfbfcba78dc71228d/58cd1e5bfbfcba2ebcaf0b1e/58cc9253fbfcba88307142d1/win7_Excelで作成.csv",
   *             "p_id": "58ca3211cce5fe2e84446cd6",
   *             "selfLink": "/storage/v1/b/attachment-files-test/o/58cbf6cbfbfcba78dc71228d/58cd1e5bfbfcba2ebcaf0b1e/58cc9253fbfcba88307142d1/win7_Excelで作成.csv",
   *             "size": 185,
   *             "timeCreated": "2017-03-19T09:43:01.178Z",
   *             "updated": "2017-03-19T18:43:01.178+09:00",
   *             "user_id": "58272f4efb90a148d8508d9c",
   *             "w_id": "58ca3211cce5fe2e84446cd3"
   *         },
   *         "use_as_search": false,
   *         "show_in_list": false
   *     },
   *     {
   *         "field_id": "Fld-xV8qUygH",
   *         "field_name": "タイトル",
   *         "dataType": "text",
   *         "value": "５５５",
   *         "use_as_search": true,
   *         "show_in_list": true
   *     },
   *     {
   *         "field_id": "Fld-hN28Dy1C",
   *         "field_name": "ステータス",
   *         "dataType": "status",
   *         "value": null,
   *         "use_as_search": true,
   *         "show_in_list": true
   *     }
   * ]
   */
  async getItem(token: string, datastoreId: string, itemId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/datastores/${datastoreId}/items/${itemId}`,
      config
    );
    return result.data.field_values;
  }

  /**
   * アイテムのアクションリストを取得
   *
   * 主な用途として、アイテムに対しての添付ファイル登録処理に利用する
   * 「内容を更新する」のaction_idを取得し、
   * execActionで、取得したaction_idを指定しアイテムに添付ファイルを登録できる
   *
   * @param token Hexabaseのtoken
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @returns 下記サンプル参照
   * "5cd3bde384f4be5808a6b3b9": {
   *     "action_id": "5cd3bde384f4be5808a6b3b9",
   *     "action_name": "内容を更新する",
   *     "display_order": 0,
   *     "crud_type": "2"
   * },
   * "5cd3bde384f4be5808a6b3ba": {
   *     "action_id": "5cd3bde384f4be5808a6b3ba",
   *     "action_name": "コピーして新規に登録する",
   *     "display_order": 0,
   *     "crud_type": "4"
   * },
   * "5cd3bde384f4be5808a6b3bb": {
   *     "action_id": "5cd3bde384f4be5808a6b3bb",
   *     "action_name": "新規登録",
   *     "display_order": 0,
   *     "crud_type": "1"
   * },
   * "5cd3bde384f4be5808a6b3bd": {
   *     "action_id": "5cd3bde384f4be5808a6b3bd",
   *     "action_name": "このデータを削除する",
   *     "display_order": 0,
   *     "crud_type": "3"
   * },
   * "5eaf86d8aa39554a445af4dd": {
   *     "action_id": "5eaf86d8aa39554a445af4dd",
   *     "action_name": "NewItem2",
   *     "display_order": 4,
   *     "crud_type": "1"
   * },
   * "5eaf94c7aa39551340a7b8f4": {
   *     "action_id": "5eaf94c7aa39551340a7b8f4",
   *     "action_name": "NewAction3",
   *     "display_order": 5,
   *     "crud_type": "1"
   * }
   */
  async getActionList(token: string, datastoreId: string, itemId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/datastores/${datastoreId}/items/${itemId}`,
      config
    );
    return result.data.item_actions;
  }

  /**
   * 指定アクションを実行する
   *
   * @param token Hexabaseのtoken
   * @param itemId 対象のアイテムID
   * @param params 下記サンプル参照
   * {
   *  is_force_update: true,
   *  history: {
   *    datastore_id: this.datastoreIds["ユーザDB"],
   *    comment: "添付ファイルの登録"
   *  },
   *  changes: [
   *    {
   *      "id": "5a26722e0e24794c979fa5b6",
   *      "value": "更新データサンプル"
   *    },
   *    {
   *      "id": "5ab84bfecce5fe5c983ea184",  // user type Fieldの場合
   *      "value": [
   *        "58272f4efb90a148d8508d9c", // user_id
   *        "5846636efb90a1024d2982fa"  // user_id
   *      ],
   *    },
   *    {
   *      "id": "5ab0c239cce5fed3a859a910", // attachment file type Fieldの場合
   *      "value": [
   *        "5ab84c1fcce5fe5c983ea185",  // file_id
   *        "5ab84c1fcce5fe5c983ea186"   // file_id
   *      ],
   *    },
   *  ]
   * }
   * @returns null
   */
  async execAction(token: string, itemId: string, actionId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/items/${itemId}/actions/${actionId}`,
      params,
      config
    );
    return result.data;
  }

  /**
   * 添付ファイルデータの取得
   *
   * バイナリデータが返されるため、
   * 画面上に表示したい場合はblob化した後に
   * window.URL.createObjectURLでImage化する等の処理が必要
   *
   * @param token Hexabaseのtoken
   * @param fileId 対象のファイルID
   * @returns 対象ファイルのバイナリデータ
   */
  async getFile(token: string, fileId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['content-type'] = 'text/plain';
    config['responseType'] = 'arraybuffer';
    const result = await axios.get(`${this.apiDomain}/files/${fileId}`, config);
    return result.data;
  }

  /**
   * 新規アイテムを作成
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param params 下記サンプル参照
   * {
   *   "item": {
   *       "field_id": "登録データ",
   *       "title": "タイトル",
   *       "assignee": "担当者",
   *   },
   *   "return_item_result": true, // true指定すると、登録されたアイテム情報を返します
   *   "related_ds_items" : {
   *       "関連データストアID_1" : [{ },{ },{ },{ }... ] ,
   *       "関連データストアID_2" : [{ },{ },{ },{ }... ] ,
   *   },  // 関連するデータストアの新規・更新・削除を指定  詳細は以下を参照
   * }
   * @returns 下記サンプル参照
   * {
   *     "error": null,
   *     "history_id": "5d661782aa39559a80479492",
   *     "item_id": "5a2647410e24792d87451e34",
   *     "item": {
   *       // Payload に "return_item_result": true を指定した場合、登録されたアイテム情報がもどる
   *     }
   * }
   *
   */
  async newItem(token: string, applicationId: string, datastoreId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/new`,
      params,
      config
    );
    return result;
  }

  /**
   * アイテムを更新
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @param params 下記サンプル参照
   * {
   *     "comment": "test-comment"
   *     "changes": [
   *         {
   *             "id": "CAR_TYPE", // display_idまたはfield_idを指定する
   *             "value": "5d4c058baa39555618ac9e98" // select type
   *         },
   *         {
   *             "id": "CAR_NAME",
   *             "value": "名前のデータ" // text tyepe
   *         },
   *         {
   *             "id": "OPTIONS",
   *             "value": [ "58bbaa27fbfcba6098746061", "596e2327fbfcbab8283dde09"]  // checkbox type
   *         }
   *     ],
   *     "groups_to_publish":["画面グループID", "システムのグループID"], // item を閲覧可能とするグループIDを指定（省略可）
   *     "use_display_id" : true,  // IDに画面IDを利用。 groups_to_publishを利用する場合はtrueとする。
   *     "is_force_update": true,
   *     "return_item_result": true, // true指定すると、登録されたアイテム情報を返します
   * }
   * @returns null ※Payload に "return_item_result": true を指定した場合、登録されたアイテム情報がもどる
   *
   */
  async editItem(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/edit/${itemId}`,
      params,
      config
    );
    return result;
  }

  /**
   * アイテムを削除
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @returns 下記サンプル参照（エラー内容が返ってくる。成功した場合はnull）
   * {
   *   "error": null,
   * }
   */
  async deleteItem(token: string, applicationId: string, datastoreId: string, itemId: string) {
    const headers = { Authorization: '' };
    headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.delete(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/delete/${itemId} `,
      { headers, data: {} }
    );
    return result;
  }

  /**
   * ファイルアップロード
   * 添付ファイルフィールドにFileをアップロード
   *
   * アップロードされたファイルはStorageに保存されるが、このAPIだけではItemへは登録されない。
   * 該当Itemへ登録するためには、returnsにふくまれるfile_idを使って、
   * アクション実行API（CreateItemWithItemIDやExecuteActionなど）をcallする必要がある。
   *
   * @param token Hexabaseのtoken
   * @param itemId 対象のアイテムID
   * @param fieldId 対象のフィールドID
   * @param formData アップロードするファイルのフォームデータ
   * （フォームデータの中身）
   * application_id: アプリケーションID
   * datastore_id: データストアID
   * filename: 添付ファイル名
   * file: 添付ファイル（blobデータ）
   * @returns 下記サンプル参照
   * {
   *   "file_id": "59af720c0e247958c7011a88"
   * }
   */
  async uploadFile(token: string, itemId: string, fieldId: string, formData: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/items/${itemId}/fields/${fieldId}/attachments`,
      formData,
      config
    );
    return result;
  }

  /**
   * ファイル削除
   * 添付ファイルフィールドのファイルを削除
   *
   * @param token Hexabaseのtoken
   * @param itemId 対象のアイテムID
   * @param fieldId 対象のフィールドID
   * @param fileId 対象のファイルID
   * @returns null
   */
  async deleteFile(token: string, itemId: string, fieldId: string, fileId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.delete(
      `${this.apiDomain}/items/${itemId}/fields/${fieldId}/attachments/${fileId}`,
      config
    );
    return result;
  }

  /**
   * ユーザを招待（Hexabase）
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *   users: [
   *     {
   *       email: this.email // 招待メールを送信するユーザのメールアドレスを指定
   *     }
   *   ],
   *   domain: window.env.VUE_APP_BASEURL, // 招待確認用ページのドメインを指定
   *   invitation_path: "signup", // 招待確認用ページのパスを指定（上記ドメインと組み合わされる）
   *   email_templates_id: window.env.VUE_APP_ET_ID // 招待メールをHexabase標準のものではなくカスタマイズしたものを使用したい場合使用（省略可能）
   * }
   * @returns 下記サンプル参照
   * [
   *   {
   *       "email": "xxx@b-eee.com",
   *       "stats": 200
   *   },
   *   {
   *       "email": "yyyC@b-eee.com",
   *       "stats": 200
   *   }
   *  ]
   */
  async inviteUser(token: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(`${this.apiDomain}/userinvite`, params, config);
    return result;
  }

  /**
   * ユーザを登録（Hexabase）
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *   "email": "グループに追加したいemailアドレス", //必須
   *   "g_id": "グループを識別するID", //必須
   *   "w_id": "ワークスペースのID",
   *   "username": "グループに追加したいユーザー名"
   * }
   * @returns 下記サンプル参照
   * {
   *     "added": false, //("bool 追加済みかどうか")
   *     "exists": false, //("b0ol 既に存在するユーザー false=新規で存在しない新しいユーザー")
   *     "user_profile": { //("ユーザープロファイルオブジェクト")
   *         "confirmed": false, //("bool ユーザーemail確認済み false=email上確認されていない")
   *         "email": "登録されたユーザーのemail",
   *         "email_sent": false, //("bool emailが送られたかどうか false=既に対象emailにemailが送られている")
   *         "profile_pics": [//("ユーザープロファイル画像オブジェクト")
   *             {
   *                 "mediaLink": "https://storage.googleapis.com/linker/pub/default.png" //("ユーザープロファイルに使用されている画像の保存先")
   *             }
   *         ],
   *         "u_id": "登録されたユーザーのID",
   *         "username": "登録されたユーザー名"
   *     }
   * }
   */
  async createUser(token: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(`${this.apiDomain}/users`, params, config);
    return result;
  }

  /**
   * グループからユーザーを削除する
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *     "g_id": "グループを識別するID", //必須
   *     "u_id": "ユーザーを識別するID", //必須
   *     "w_id": "ワークスペースを識別するID"
   * }
   * @returns 下記サンプル参照（エラー内容が返ってくる。成功した場合はnull）
   * {
   *     "error": null
   * }
   */
  async deleteUsers(token: string, params: any) {
    const headers = { Authorization: '' };
    headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.delete(`${this.apiDomain}/users`, {
      headers,
      data: params
    });
    return result;
  }

  /**
   * 関連レコードの取得
   * @param token Hexabaseのtoken
   * @param datastoreId 関連元のデータストアID
   * @param itemId 対象のアイテムID
   * @param linkedDatastoreId 関連先のデータストアID
   * @returns 下記サンプル参照
   * {
   * "datastore_id": "5cc25d1e84f4be574418d580",
   *   "fields": {
   *     "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0": {
   *       "id": "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0",
   *       "f_id": "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "重要度",
   *       "names": {
   *         "en": "重要度",
   *         "ja": "重要度"
   *       },
   *       "display_id": "重要度",
   *       "dataType": "text",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 5,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false
   *     },
   *     "3e9a47e1-ec41-477a-8b18-f324c67d9116": {
   *       "id": "3e9a47e1-ec41-477a-8b18-f324c67d9116",
   *       "f_id": "3e9a47e1-ec41-477a-8b18-f324c67d9116",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "担当者",
   *       "names": {
   *         "en": "担当者",
   *         "ja": "担当者"
   *       },
   *       "display_id": "担当者",
   *       "dataType": "text",
   *       "search": true,
   *       "show_list": true,
   **       "as_title": false,
   **       "status": false,
   **       "fieldIndex": 2,
   **       "title_order": 0,
   **       "full_text": false,
   **       "unique": false,
   **       "min_value": "",
   **       "max_value": "",
   **       "hideOnInput": false
   **     },
   **     "46564f59-fbf4-40f0-a5a1-2edaee92a9c7": {
   **       "id": "46564f59-fbf4-40f0-a5a1-2edaee92a9c7",
   **       "f_id": "46564f59-fbf4-40f0-a5a1-2edaee92a9c7",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "開始日",
   *       "names": {
   *         "en": "開始日",
   *         "ja": "開始日"
   *       },
   *       "display_id": "開始日",
   *       "dataType": "text",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 3,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false
   *     },
   *     "5cc4dbb284f4be926c491d96": {
   *       "id": "5cc4dbb284f4be926c491d96",
   *       "f_id": "5cc4dbb284f4be926c491d96",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "Num1",
   *       "names": {
   *         "en": "Num1",
   *         "ja": "Num1"
   *       },
   *       "display_id": "Fld-fjpeoxrY",
   *       "dataType": "number",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ],
   *       "num_info": {
   *         "prefix": "Code:",
   *         "suffix": "",
   *         "no_comma": true
   *       }
   *     },
   *     "5cc4dbc084f4be926c491d97": {
   *       "id": "5cc4dbc084f4be926c491d97",
   *       "f_id": "5cc4dbc084f4be926c491d97",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "Num2",
   *       "names": {
   *         "en": "Num2",
   *         "ja": "Num2"
   *       },
   *       "display_id": "Fld-xGx9u6A2",
   *       "dataType": "number",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 7,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ]
   *     },
   *     "5cc4dbcd84f4be926c491d98": {
   *       "id": "5cc4dbcd84f4be926c491d98",
   *       "f_id": "5cc4dbcd84f4be926c491d98",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "Calc",
   *       "names": {
   *         "en": "Calc",
   *         "ja": "Calc"
   *       },
   *       "display_id": "Fld-fAcrbFfs",
   *       "dataType": "number",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ],
   *       "num_info": {
   *         "prefix": "\\",
   *         "suffix": "yen",
   *         "no_comma": false
   *       }
   *     },
   *     "5cc4dbf484f4be926c491d99": {
   *       "id": "5cc4dbf484f4be926c491d99",
   *       "f_id": "5cc4dbf484f4be926c491d99",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "This is a Label",
   *       "names": {
   *         "en": "This is a Label",
   *         "ja": "This is a Label"
   *       },
   *       "display_id": "Fld-XbOD2IvT",
   *       "dataType": "label",
   *       "search": false,
   *       "show_list": false,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ]
   *     },
   *     "5cc8fa5484f4be926c491d9a": {
   *       "id": "5cc8fa5484f4be926c491d9a",
   *       "f_id": "5cc8fa5484f4be926c491d9a",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "計算式フィールド",
   *       "names": {
   *         "en": "計算式フィールド",
   *         "ja": "計算式フィールド"
   *       },
   *       "display_id": "Fld-1hfacFJP",
   *       "dataType": "calc",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ],
   *       "calc_info": {
   *         "formula": "{Fld-xGx9u6A2}   /   {Fld-fAcrbFfs}+{Fld-xGx9u6A2}",
   *         "calc_target_fields": [
   *           "5cc4dbc084f4be926c491d97",
   *           "5cc4dbcd84f4be926c491d98"
   *         ],
   *         "prefix": "\\",
   *         "suffix": "",
   *         "no_comma": false
   *       }
   *     },
   *     "5cda5ac384f4bec92c8b8d03": {
   *       "id": "5cda5ac384f4bec92c8b8d03",
   *       "f_id": "5cda5ac384f4bec92c8b8d03",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "NoCalc",
   *       "names": {
   *         "en": "NoCalc",
   *         "ja": "NoCalc"
   *       },
   *       "display_id": "Fld-7ANOlTq4",
   *       "dataType": "calc",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false,
   *       "access_keys": [
   *         "5c6363da84f4be7de035044a",
   *         "5c6363da84f4be7de035044c",
   *         "5ca5561484f4be19cc01d378",
   *         "5ca5562084f4be19cc01d37a"
   *       ],
   *       "calc_info": {
   *         "formula": "",
   *         "calc_target_fields": [],
   *         "prefix": "",
   *         "suffix": "",
   *         "no_comma": false
   *       }
   *     },
   *     "6f5779f7-6d34-46ce-afd1-9e83c77260b9": {
   *       "id": "6f5779f7-6d34-46ce-afd1-9e83c77260b9",
   *       "f_id": "6f5779f7-6d34-46ce-afd1-9e83c77260b9",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "完了予定日",
   *       "names": {
   *         "en": "完了予定日",
   *         "ja": "完了予定日"
   *       },
   *       "display_id": "完了予定日",
   *       "dataType": "text",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": false,
   *       "fieldIndex": 4,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false
   *     },
   *     "a38850be-4fb9-4025-a297-4c500c793716": {
   *       "id": "a38850be-4fb9-4025-a297-4c500c793716",
   *       "f_id": "a38850be-4fb9-4025-a297-4c500c793716",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "ステータス",
   *       "names": {
   *         "en": "ステータス",
   *         "ja": "ステータス"
   *       },
   *       "display_id": "ステータス",
   *       "dataType": "status",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": false,
   *       "status": true,
   *       "fieldIndex": 0,
   *       "title_order": 0,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false
   *     },
   *     "f99f6b08-dd8b-4816-adc7-9b28555a1e93": {
   *       "id": "f99f6b08-dd8b-4816-adc7-9b28555a1e93",
   *       "f_id": "f99f6b08-dd8b-4816-adc7-9b28555a1e93",
   *       "w_id": "5c5fa7da84f4be4250aaee27",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "field_csv_name": "",
   *       "name": "タスク",
   *       "names": {
   *         "en": "タスク",
   *         "ja": "タスク"
   *       },
   *       "display_id": "タスク",
   *       "dataType": "text",
   *       "search": true,
   *       "show_list": true,
   *       "as_title": true,
   *       "status": false,
   *       "fieldIndex": 1,
   *       "title_order": 1,
   *       "full_text": false,
   *       "unique": false,
   *       "min_value": "",
   *       "max_value": "",
   *       "hideOnInput": false
   *     }
   *   },
   *   "column_settings": [
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "label",
   *       "displayID": "Fld-XbOD2IvT",
   *       "displayName": "This is a Label",
   *       "dslookup_info": null,
   *       "field": "5cc4dbf484f4be926c491d99",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "This is a Label",
   *         "ja": "This is a Label"
   *       },
   *       "num_info": null,
   *       "order": 6,
   *       "search": false,
   *       "show_list": false,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": {
   *         "formula": "{Fld-xGx9u6A2}   /   {Fld-fAcrbFfs}+{Fld-xGx9u6A2}",
   *         "calc_target_fields": [
   *           "5cc4dbc084f4be926c491d97",
   *           "5cc4dbcd84f4be926c491d98"
   *         ],
   *         "prefix": "\\",
   *         "suffix": "",
   *         "no_comma": false
   *       },
   *       "dataType": "calc",
   *       "displayID": "Fld-1hfacFJP",
   *       "displayName": "計算式フィールド",
   *       "dslookup_info": null,
   *       "field": "5cc8fa5484f4be926c491d9a",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "計算式フィールド",
   *         "ja": "計算式フィールド"
   *       },
   *       "num_info": null,
   *       "order": 7,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": {
   *         "formula": "",
   *         "calc_target_fields": [],
   *         "prefix": "",
   *         "suffix": "",
   *         "no_comma": false
   *       },
   *       "dataType": "calc",
   *       "displayID": "Fld-7ANOlTq4",
   *       "displayName": "NoCalc",
   *       "dslookup_info": null,
   *       "field": "5cda5ac384f4bec92c8b8d03",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "NoCalc",
   *         "ja": "NoCalc"
   *       },
   *       "num_info": null,
   *       "order": 8,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "number",
   *       "displayID": "Fld-fjpeoxrY",
   *       "displayName": "Num1",
   *       "dslookup_info": null,
   *       "field": "5cc4dbb284f4be926c491d96",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "Num1",
   *         "ja": "Num1"
   *       },
   *       "num_info": {
   *         "prefix": "Code:",
   *         "suffix": "",
   *         "no_comma": true
   *       },
   *       "order": 3,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "status",
   *       "displayID": "ステータス",
   *       "displayName": "ステータス",
   *       "dslookup_info": null,
   *       "field": "a38850be-4fb9-4025-a297-4c500c793716",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "ステータス",
   *         "ja": "ステータス"
   *       },
   *       "num_info": null,
   *       "order": 10,
   *       "search": true,
   *       "show_list": true,
   *       "statuses": [
   *         {
   *           "id": "5cc25d2584f4bed46001b75f",
   *           "color": "",
   *           "s_id": "5cc25d2584f4bed46001b75f",
   *           "name": "進行中"
   *         },
   *         {
   *           "id": "5cc25d2584f4bed46001b75e",
   *           "color": "",
   *           "s_id": "5cc25d2584f4bed46001b75e",
   *           "name": "未着手"
   *         },
   *         {
   *           "id": "5cc25d2584f4bed46001b75c",
   *           "color": "",
   *           "s_id": "5cc25d2584f4bed46001b75c",
   *           "name": "完了"
   *         },
   *         {
   *           "id": "5cc25d2584f4bed46001b75d",
   *           "color": "",
   *           "s_id": "5cc25d2584f4bed46001b75d",
   *           "name": "承認待ち"
   *         }
   *       ],
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "number",
   *       "displayID": "Fld-fAcrbFfs",
   *       "displayName": "Calc",
   *       "dslookup_info": null,
   *       "field": "5cc4dbcd84f4be926c491d98",
   *       "field_index": 0,
   *       "file_info": null,
   *       "names": {
   *         "en": "Calc",
   *         "ja": "Calc"
   *       },
   *       "num_info": {
   *         "prefix": "\\",
   *         "suffix": "yen",
   *         "no_comma": false
   *       },
   *       "order": 5,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": true,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "text",
   *       "displayID": "タスク",
   *       "displayName": "タスク",
   *       "dslookup_info": null,
   *       "field": "f99f6b08-dd8b-4816-adc7-9b28555a1e93",
   *       "field_index": 1,
   *       "file_info": null,
   *       "names": {
   *         "en": "タスク",
   *         "ja": "タスク"
   *       },
   *       "num_info": null,
   *       "order": 11,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "text",
   *       "displayID": "担当者",
   *       "displayName": "担当者",
   *       "dslookup_info": null,
   *       "field": "3e9a47e1-ec41-477a-8b18-f324c67d9116",
   *       "field_index": 2,
   *       "file_info": null,
   *       "names": {
   *         "en": "担当者",
   *         "ja": "担当者"
   *       },
   *       "num_info": null,
   *       "order": 1,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "text",
   *       "displayID": "開始日",
   *       "displayName": "開始日",
   *       "dslookup_info": null,
   *       "field": "46564f59-fbf4-40f0-a5a1-2edaee92a9c7",
   *       "field_index": 3,
   *       "file_info": null,
   *       "names": {
   *         "en": "開始日",
   *         "ja": "開始日"
   *       },
   *       "num_info": null,
   *       "order": 2,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "text",
   *       "displayID": "完了予定日",
   *       "displayName": "完了予定日",
   *       "dslookup_info": null,
   *       "field": "6f5779f7-6d34-46ce-afd1-9e83c77260b9",
   *       "field_index": 4,
   *       "file_info": null,
   *       "names": {
   *         "en": "完了予定日",
   *         "ja": "完了予定日"
   *       },
   *       "num_info": null,
   *       "order": 9,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "text",
   *       "displayID": "重要度",
   *       "displayName": "重要度",
   *       "dslookup_info": null,
   *       "field": "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0",
   *       "field_index": 5,
   *       "file_info": null,
   *       "names": {
   *         "en": "重要度",
   *         "ja": "重要度"
   *       },
   *       "num_info": null,
   *       "order": 0,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     },
   *     {
   *       "as_title": false,
   *       "autonum_info": null,
   *       "calc_info": null,
   *       "dataType": "number",
   *       "displayID": "Fld-xGx9u6A2",
   *       "displayName": "Num2",
   *       "dslookup_info": null,
   *       "field": "5cc4dbc084f4be926c491d97",
   *       "field_index": 7,
   *       "file_info": null,
   *       "names": {
   *         "en": "Num2",
   *         "ja": "Num2"
   *       },
   *       "num_info": null,
   *       "order": 4,
   *       "search": true,
   *       "show_list": true,
   *       "users_info": null
   *     }
   *   ],
   *   "items": [
   *     {
   *       "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0": "高",
   *       "3e9a47e1-ec41-477a-8b18-f324c67d9116": "",
   *       "46564f59-fbf4-40f0-a5a1-2edaee92a9c7": "",
   *       "5cc8fa5484f4be926c491d9a": null,
   *       "6f5779f7-6d34-46ce-afd1-9e83c77260b9": "",
   *       "_id": "5cc25d3584f4be574418d58b",
   *       "access_keys": [
   *         "5c5fa7da84f4be4250aaee29",
   *         "5c5fa7da84f4be4250aaee26"
   *       ],
   *       "created_at": "2019-04-26T01:21:57.008Z",
   *       "created_by": "IMPORT",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "f99f6b08-dd8b-4816-adc7-9b28555a1e93": "H",
   *       "i_id": "5cc25d3584f4be574418d58b",
   *       "labeled_at": "2019-05-02T13:20:57.652Z",
   *       "labels": [
   *         {
   *           "c6934716-9bab-4f35-b2a5-aff9169d3a1a": "高"
   *         }
   *       ],
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "rev_no": 2,
   *       "status_id": "5cc25d2584f4bed46001b75f",
   *       "title": "H",
   *       "updated_at": "2019-05-02T13:20:57.61Z",
   *       "updated_by": "5c5fa7aa84f4be4250aaee25"
   *     },
   *     {
   *       "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0": "高",
   *       "3e9a47e1-ec41-477a-8b18-f324c67d9116": "",
   *       "46564f59-fbf4-40f0-a5a1-2edaee92a9c7": "",
   *       "5cc8fa5484f4be926c491d9a": null,
   *       "6f5779f7-6d34-46ce-afd1-9e83c77260b9": "",
   *       "_id": "5cc25d3584f4be574418d58c",
   *       "access_keys": [
   *         "5c5fa7da84f4be4250aaee29",
   *         "5c5fa7da84f4be4250aaee26"
   *       ],
   *       "created_at": "2019-04-26T01:21:57.008Z",
   *       "created_by": "IMPORT",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "f99f6b08-dd8b-4816-adc7-9b28555a1e93": "G",
   *       "i_id": "5cc25d3584f4be574418d58c",
   *       "labeled_at": "2019-05-02T13:20:56.403Z",
   *       "labels": [
   *         {
   *           "c6934716-9bab-4f35-b2a5-aff9169d3a1a": "高"
   *         }
   *       ],
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "rev_no": 2,
   *       "status_id": "5cc25d2584f4bed46001b75c",
   *       "title": "G",
   *       "updated_at": "2019-05-02T13:20:56.366Z",
   *       "updated_by": "5c5fa7aa84f4be4250aaee25"
   *     },
   *     {
   *       "294c2cf8-c64c-4b0f-8d2d-4adaf64477e0": "高",
   *       "3e9a47e1-ec41-477a-8b18-f324c67d9116": "",
   *       "46564f59-fbf4-40f0-a5a1-2edaee92a9c7": "",
   *       "5cc4dbb284f4be926c491d96": 57007,
   *       "5cc4dbc084f4be926c491d97": 1233,
   *       "5cc4dbcd84f4be926c491d98": 1264,
   *       "5cc8fa5484f4be926c491d9a": 55608,
   *       "6f5779f7-6d34-46ce-afd1-9e83c77260b9": "",
   *       "_id": "5cc25d3584f4be574418d58d",
   *       "access_keys": [
   *         "5c5fa7da84f4be4250aaee29",
   *         "5c5fa7da84f4be4250aaee26"
   *       ],
   *       "created_at": "2019-04-26T01:21:57.008Z",
   *       "created_by": "IMPORT",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "f99f6b08-dd8b-4816-adc7-9b28555a1e93": "F",
   *       "i_id": "5cc25d3584f4be574418d58d",
   *       "labeled_at": "2019-05-07T01:46:49.749Z",
   *       "labels": [
   *         {
   *           "c6934716-9bab-4f35-b2a5-aff9169d3a1a": "高"
   *         }
   *       ],
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "rev_no": 18,
   *       "status_id": "5cc25d2584f4bed46001b75f",
   *       "title": "F",
   *       "updated_at": "2019-05-07T01:46:49.69Z",
   *       "updated_by": "5c5fa7aa84f4be4250aaee25"
   *     }
   *   ],
   *   "stateflowActions": [
   *     {
   *       "_id": "5cc25d2584f4bed46001b761",
   *       "a_id": "5cc25d2584f4bed46001b761",
   *       "access_keys": [],
   *       "created_at": "2019-04-26T01:21:41.656Z",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "description": "",
   *       "display_order": 0,
   *       "isOwnedBySystem": true,
   *       "is_status_action": false,
   *       "name": "DEFAULTS_FOR_IMPORTER.DEFAULT_ACTION_NAME_UPDATE",
   *       "operation": "2",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "set_status": "",
   *       "slack_channels": [],
   *       "updated_at": "2019-04-26T01:21:41.656Z",
   *       "user_slack_channels": {},
   *       "w_id": "5c5fa7da84f4be4250aaee27"
   *     },
   *     {
   *       "_id": "5cc25d2584f4bed46001b762",
   *       "a_id": "5cc25d2584f4bed46001b762",
   *       "access_keys": [],
   *       "created_at": "2019-04-26T01:21:41.656Z",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "description": "",
   *       "display_order": 1,
   *       "isOwnedBySystem": true,
   *       "is_status_action": false,
   *       "name": "DEFAULTS_FOR_IMPORTER.DEFAULT_ACTION_NAME_COPY",
   *       "operation": "4",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "set_status": "",
   *       "slack_channels": [],
   *       "updated_at": "2019-04-26T01:21:41.656Z",
   *       "user_slack_channels": {},
   *       "w_id": "5c5fa7da84f4be4250aaee27"
   *     },
   *     {
   *       "_id": "5cc25d2584f4bed46001b763",
   *       "a_id": "5cc25d2584f4bed46001b763",
   *       "access_keys": [],
   *       "created_at": "2019-04-26T01:21:41.656Z",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "description": "",
   *       "display_order": 2,
   *       "isOwnedBySystem": true,
   *       "is_status_action": false,
   *       "name": "DEFAULTS_FOR_IMPORTER.DEFAULT_ACTION_NAME_NEW",
   *       "operation": "1",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "set_status": "5cc25d2584f4bed46001b75f",
   *       "slack_channels": [],
   *       "updated_at": "2019-04-26T01:21:41.656Z",
   *       "user_slack_channels": {},
   *       "w_id": "5c5fa7da84f4be4250aaee27"
   *     },
   *     {
   *       "_id": "5cc25d2584f4bed46001b764",
   *       "a_id": "5cc25d2584f4bed46001b764",
   *       "access_keys": [],
   *       "created_at": "2019-04-26T01:21:41.656Z",
   *       "d_id": "5cc25d1e84f4be574418d580",
   *       "description": "",
   *       "display_order": 3,
   *       "isOwnedBySystem": true,
   *       "is_status_action": false,
   *       "name": "DEFAULTS_FOR_IMPORTER.DEFAULT_ACTION_NAME_DELETE",
   *       "operation": "3",
   *       "p_id": "5c6363d984f4be7de0350445",
   *       "set_status": "",
   *       "slack_channels": [],
   *       "updated_at": "2019-04-26T01:21:41.656Z",
   *       "user_slack_channels": {},
   *       "w_id": "5c5fa7da84f4be4250aaee27"
   *     }
   *   ]
   * }
   */
  async getRelationItems(
    token: string,
    datastoreId: string,
    itemId: string,
    linkedDatastoreId: string
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/datastores/${datastoreId}/items/${itemId}/links/${linkedDatastoreId}`,
      config
    );
    return result.data.items;
  }

  /**
   * ユーザーの初期登録の確認
   * ユーザーの初期登録用のメールに添付されたURLから、ユーザーを確認し、確認情報を確認済みにする
   *
   * @param confirmationId ユーザー初期登録シーケンスのconfirmation ID
   * @returns 下記サンプル参照
   * { "user": {
   *     "confirmation_id": "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", //上記の確認ID
   *     "confirmed": bool, //true=確認済,false=未確認
   *     "email": "hogehoge@gmail.com", //初期登録されたemail
   *     "id": "5e8ffd39d4b3e00006344d1e", //システム内部のuserID
   *     "isElapsed": bool, //true =使用期限切れ, false=使用可能
   *     "username": "登録されたユーザー名"
   * }}
   */
  async getConfirmUserData(confirmationId: string) {
    const result = await axios.get(
      `${this.apiDomain}/users/registration/confirm?id=${confirmationId}`
    );
    return result;
  }

  /**
   * パスワード登録
   *
   * UserConfirm後、Hexabaseを初めて利用するユーザー情報（ユーザ名、パスワード等）を登録する。
   * additional_infoにはそのユーザー固有の情報をMap形式で登録することができる。
   *
   * @param params 下記サンプル参照
   * {
   *   "confirmation_id": "確認用のID", //必須
   *   "email": "登録されるemail", //必須
   *   "username": "登録されるユーザー名",
   *   "password": "設定するパスワード", // 必須
   *   "workspace": "作成するワークスペース名",
   *   "additional_info": {
   *       "field_name1": "value", // field_name, value 共に自由に指定可能
   *       "field_name2": "value"
   *   }
   * }
   */
  async RegisterUser(params: any) {
    const result = await axios.post(`${this.apiDomain}/users/registration/confirm`, params);
    return result;
  }

  /**
   * パスワードリセット用confirmation_idを取得
   *
   * @returns confirmation_id
   */
  async getConfirmations() {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    const result = await axios.post(`${this.apiDomain}/confirmations`, config);
    return result.data.confirmation_id;
  }

  /**
   * パスワード初期化リクエスト
   *
   * セキュリティ対策のため、事前にconfirmation_idを取得して、
   * Payloadにconfirmation_idを付与する必要がある。
   *
   * @param params 下記サンプル参照
   * {
   *     "email": "XXX@XXX.com", // パスワードをリセットしたいユーザのアカウント
   *     "host":"http://syzygy-host-url.com", // パスワードリセット確認用ページのドメインを指定（http:// or https://を含んだ形）
   *     "email_templates_id": "XXXXXXXXXXX", // 確認メールをHexabase標準のものではなくカスタマイズしたものを使用したい場合使用（省略可能）
   * }
   * @returns 下記サンプル参照
   * {
   *   "valid_email": true //パスワード初期化をしたいemailの有無
   * }
   */
  async passwordOperation(params: any, method = 'post') {
    const result =
      method === 'post'
        ? await axios.post(`${this.apiDomain}/users/password/forgot`, params)
        : await axios.put(`${this.apiDomain}/users/password/forgot`, params);
    return result;
  }

  /**
   * パスワード再登録
   *
   * パスワード初期化リクエストを行った後、
   * 送信された確認用メール内リンクから遷移したページで使用する
   *
   * @param params 下記サンプル参照
   * {
   *   "new_password": "必須 新規作成パスワード 例：test",
   *   "confirm_password": "必須 確認用パスワード 例：test この値は新規作成の値と同じでなければならない",
   *   "id": "必須 パスワード初期化開始のapi送信後にemailのリンク内部に埋め込まれた情報をここに入れる"
   * }
   * @returns null
   */
  async setNewPassword(params: any) {
    const result = await axios.put(`${this.apiDomain}/users/password/forgot`, params);
    return result;
  }

  /**
   * ログイン後、パスワード更新
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *   "confirm_password":"必須 新しいパスワードの確認 //入力内容はnew_paswordと同じで有る必要が有る",
   *   "new_password":"必須 新しいパスワード",
   *   "old_password":"必須 今まで使用していたパスワード"
   * }
   * @returns 下記サンプル参照
   * {
   *   "error": null //エラーの有無
   * }
   */
  async setPassword(token: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.put(`${this.apiDomain}/users/password`, params, config);
    return result;
  }

  /**
   * メールアドレス更新申請（メールアドレス変更申請画面で実行）
   *
   * 変更先のメールアドレス宛に、
   * メールアドレス変更確認画面のリンクを含んだメールを送信する
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *   email: this.newEmail, // 変更先のメールアドレス
   *   registration_domain: window.env.VUE_APP_BASEURL, // http or httpsを含まない確認用ページドメインを指定
   *   registration_path: "updateemail", // 確認用ページのパスを指定
   *   email_templates_id: window.env.VUE_APP_ET_ID // 確認メールをHexabase標準のものではなくカスタマイズしたものを使用したい場合使用（省略可能）
   * }
   */
  async setMailAddress(token: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(`${this.apiDomain}/users/email`, params, config);
    return result;
  }

  /**
   * メールアドレス変更情報確認（メールアドレス変更確認画面で実行）
   *
   * メールアドレス更新申請を行った後のメールアドレス変更確認画面で実行する
   *
   * @param token Hexabaseのtoken
   * @param confirmationId メールアドレス変更用のconfirmation ID
   * @returns 下記サンプル参照
   * user: {
   *   confirmation_id: "r9br24vv0kztykofymyza64mre2e1kc38c328cvcjlhzb0htsdv48mr9xb7y4dws3b1qlvnaheoc2dknchh0a6it1yhm1xy82l9v"
   *   confirmed: true
   *   current_workspace_id: "5e9678e8d4b3e00006eb8745"
   *   email: "xxxxxx@asnet.co.jp" // 変更元メールアドレス
   *   email_confirmed: false
   *   id: "5fbddaf6391f260001a75f78"
   *   isElapsed: false
   *   tmp_email: "zzzzzz@asnet.co.jp" // 変更先メールアドレス
   *   username: "APIテスト"
   * },
   * workspace: {
   *   access_key: "5f76ad7daa3d8a0001269957"
   *   created_at: "2020-10-02T13:33:01.876593772+09:00"
   *   disable_ui_access: true
   *   display_id: "ユーザ"
   *   g_id: "5f76ad7daa3d8a0001269956"
   *   id: "5f76ad7daa3d8a0001269956"
   *   index: 0
   *   is_root: false
   *   name: "ユーザ"
   * }
   */
  async confirmUserEmail(token: string, confirmationId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/users/confirmations/${confirmationId}`,
      config
    );
    return result;
  }

  /**
   * メールアドレス更新（メールアドレス変更確認画面で実行）
   *
   * メールアドレス変更情報確認を行い、問題なければ実行する
   *
   * @param token Hexabaseのtoken
   * @param params 下記サンプル参照
   * {
   *  confirmation_id: 変更先メールアドレスに送信されたメール内リンクに含まれるconfirmation_id
   * }
   */
  async updateUserEmail(token: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(`${this.apiDomain}/users/confirmations`, params, config);
    return result;
  }

  /**
   * ユーザinformation取得
   *
   * recursive=true で、グループ階層の下をたどって、所属するすべてのユーザーを取得する。
   *
   * @param token Hexabaseのtoken
   * @param groupId 対象のグループID
   * @param recursive true or false
   * @returns 下記サンプル参照
   * {
   *    "members": [
   *        {
   *            "u_id": "ユーザーID",
   *            "username": "ユーザー名",
   *            "email": "ユーザーのemail",
   *            "profile_pic": "https://storage.googleapis.com/linker/pub/default.png", //("画像のストレージ領域")
   *            "confirmed": true, //("bool, このユーザーが確認済みかどうか")
   *            "email_sent": true,//("bool, このユーザーにemailが送られたかどうか")
   *            "is_sv": true //("bool, このユーザーがスーパバイザー型かどうか、true=supervisorである")
   *        } //("ユーザー毎にオブジェクトが返される")
   *    ],
   *    "count": 1//("このグループ内部にいるユーザー数")
   * }
   */
  async getGroupUsers(token: string, groupId: string, recursive: boolean) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/groups/${groupId}/users?recursive=${recursive}`,
      config
    );
    return result;
  }

  /**
   * データレポートに紐づく一覧を取得（条件を指定してレポートデータを取得）
   * 条件なしで取得する際はparamsのconditionsを[]にする
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param reportId 対象のデータレポートID
   * @param params 下記サンプル参照
   * {
   *   // 検索条件の指定
   *  "conditions" : [
   *    {
   *     "id" : DataReportフィールドのdisplay_id または、"rpf_id"(/conditions APIで返されるレポートフィールドID) を指定,
   *     "search_value": 検索条件を配列で指定（サンプルを参照） ※アイテム一覧の指定と同様、フィールドタイプごとに指定内容が異なる
   *    },
   *  ],
   *  // ページネーション
   *  "per_page" : 10, // 1ページたあたりの取得件数（省略、または、ゼロを指定すると、対象データ全件を返します）
   *  "page" : 1, // ページ数
   *  // ソート順の指定（複数のソートキー指定）
   *  "sort_fields": [
   *      {"id": "Status", "order": "desc"}, // idには、 display_id または rpf_idを指定
   *      {"id": "Category", "order": "asc"} // order にはソート順を指定 desc : 降順 ask : 昇順（省略すると昇順となります）
   *  ],
   *  // ソート順の指定（単一のソートキー指定）※"sort_fields": []が指定されている場合は、この指定は無視されれます（sort_fieldsが優先される）
   *  "sort_field_id": "No", // display_id または rpf_idを指定
   *  "sort_order": "desc",  // asc または descを指定
   *  // 結果をCSV形式取得する場合、"csv"と指定（省略するとJSON形式となる）
   *  "format": "csv"
   * @returns 下記サンプル参照（下記はJSON形式の返り値。formatにcsvを指定した場合は、1行目がヘッダ行、2行目以降にデータをカンマ区切りで出力される）
   * {
   *   "error": ""
   *   "report_title": "SimpleJoin", // レポート名
   *   "totalItems": 40932, // 全データ件数
   *   "item_index_from": 0, // 結果データの最初のIndex番号
   *   "item_index_to": 4, // 結果データの最後のIndex番号
   *   "report_results": [ // 取得データ
   *       {
   *           "NOTE": "B",
   *           "TITLE": "タスクC",
   *           "CATEGORY": "B",
   *           "STATUS": "New",
   *           "NUM": 0,
   *           "DUE_DATE": "2016/01/01",
   *           "items": [ // 集計レポートの場合、集計結果に含まれる該当Item,DatasstoreのIDが含まれる
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2ff0"
   *               },
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2ff2"
   *               }
   *           ],
   *           "i_id": "5f267f1628dc5c6988bc2fef" // 集計されていないレポートの場合は、該当アイテムのIDが含まれる
   *       },
   *       {
   *           "NOTE": "B",
   *           "TITLE": "案件（明細あり）",
   *           "CATEGORY": "B",
   *           "STATUS": "In Review",
   *           "NUM": 0,
   *           "DUE_DATE": "2016/01/01"
   *           "items": [
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2ff1"
   *               }
   *           ]
   *       },
   *       {
   *           "NOTE": "B",
   *           "TITLE": "",
   *           "CATEGORY": "B",
   *           "STATUS": "New",
   *           "NUM": 0,,
   *           "items": [
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2fef"
   *               }
   *           ]
   *       },
   *       {
   *           "NOTE": "B",
   *           "TITLE": "１１１新規起票",
   *           "CATEGORY": "B",
   *           "STATUS": "New",
   *           "NUM": 0,,
   *           "items": [
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2fee"
   *               }
   *           ]
   *       },
   *       {
   *           "NOTE": "B",
   *           "TITLE": "タスクB",
   *           "CATEGORY": "B",
   *           "STATUS": "New",
   *           "NUM": 0,
   *           "DUE_DATE": "2016/01/01",
   *           "items": [
   *               {
   *                   "d_id": "5f267f1028dc5c6988bc2fe0",
   *                   "i_id": "5f267f1628dc5c6988bc2fbb"
   *               }
   *           ]
   *       }
   *   ],
   *   "report_fields": [ // 取得レポートのfield情報
   *       {
   *           "title": "Title",
   *           "rpf_id": "4a0b2f8a-f332-4579-a2d4-e9b5971d9e80",
   *           "display_id" : "TITLE",
   *           "data_type": "text",
   *           "display_type": "",
   *           "align": "",
   *           "width": 235,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [],
   *           "f_id": "5c69148384f4be344c2ff0f1",
   *           "d_id": "5c69148384f4be1f241fedcd",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "Due Date",
   *           "rpf_id": "b752bf7f-9f0d-4370-ac34-45b9be01453f",
   *           "display_id" : "DUE_DATE",
   *           "data_type": "date",
   *           "display_type": "",
   *           "align": "",
   *           "width": 106,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [],
   *           "f_id": "5c69148384f4be344c2ff0f5",
   *           "d_id": "5c69148384f4be1f241fedcd",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "Category",
   *           "rpf_id": "7b9db4bb-3516-4cd7-946a-97c1ddcdca2a",
   *           "display_id" : "CATEGORY",
   *           "data_type": "select",
   *           "display_type": "text",
   *           "align": "left",
   *           "width": 94,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [
   *               {
   *                   "cond_rpf_id": "7b9db4bb-3516-4cd7-946a-97c1ddcdca2a",
   *                   "condition": "eq",
   *                   "cond_type": "text",
   *                   "value": "B",
   *                   "bg_color": "#dcecf3",
   *                   "ft_color": "#165896",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               },
   *               {
   *                   "cond_rpf_id": "7b9db4bb-3516-4cd7-946a-97c1ddcdca2a",
   *                   "condition": "eq",
   *                   "cond_type": "text",
   *                   "value": "C",
   *                   "bg_color": "#dcecf3",
   *                   "ft_color": "#165896",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               },
   *               {
   *                   "cond_rpf_id": "7b9db4bb-3516-4cd7-946a-97c1ddcdca2a",
   *                   "condition": "eq",
   *                   "cond_type": "text",
   *                   "value": "A",
   *                   "bg_color": "#f4eaf3",
   *                   "ft_color": "#9e9e9e",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               }
   *           ],
   *           "f_id": "5c6363ed84f4be7de0350452",
   *           "d_id": "5c6363ed84f4be2550bfe8ff",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "Status",
   *           "rpf_id": "85c15eca-1b7a-4423-9969-55f363efc4c1",
   *           "display_id" : "STATUS",
   *           "data_type": "status",
   *           "display_type": "text",
   *           "align": "left",
   *           "width": 97,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [
   *               {
   *                   "cond_rpf_id": "85c15eca-1b7a-4423-9969-55f363efc4c1",
   *                   "condition": "eq",
   *                   "cond_type": "text",
   *                   "value": "新規",
   *                   "bg_color": "#f9e9e9",
   *                   "ft_color": "#c01717",
   *                   "apply_row": true,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               },
   *               {
   *                   "cond_rpf_id": "2d9bfd30-e194-4492-a93e-e6d0e4323685",
   *                   "condition": "eq",
   *                   "cond_type": "text",
   *                   "value": "",
   *                   "bg_color": "#f0f7df",
   *                   "ft_color": "#2a7850",
   *                   "apply_row": false,
   *                   "use_field_ref": true,
   *                   "value_rpf_id": "4552a666-508d-419c-ba99-7c9a16b24894",
   *                   "font_size": "small",
   *                   "font_style": "italic",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               }
   *           ],
   *           "f_id": "5c6363ed84f4be7de0350453",
   *           "d_id": "5c6363ed84f4be2550bfe8ff",
   *           "merge_cells": false,
   *           "hide": true,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "note",
   *           "rpf_id": "4552a666-508d-419c-ba99-7c9a16b24894",
   *           "display_id" : "NOTE",
   *           "data_type": "textarea",
   *           "display_type": "",
   *           "align": "",
   *           "width": 174,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [],
   *           "f_id": "5c6459de84f4be4588a77288",
   *           "d_id": "5c6363ed84f4be2550bfe8ff",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "Num",
   *           "rpf_id": "8a20643b-fe36-45f1-bbd6-b2b6e4296a54",
   *           "display_id" : "NUM",
   *           "data_type": "number",
   *           "display_type": "number",
   *           "align": "right",
   *           "width": 200,
   *           "negative_sign_type": "triangle",
   *           "num_format": "comma-separeted",
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [],
   *           "f_id": "5c91f38a84f4be29540c4e9c",
   *           "d_id": "5c6363ed84f4be2550bfe8ff",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": false
   *       },
   *       {
   *           "title": "X",
   *           "rpf_id": "X",
   *           "data_type": "number",
   *           "display_type": "number",
   *           "align": "right",
   *           "width": 104,
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [
   *               {
   *                   "cond_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "condition": "eq",
   *                   "cond_type": "number",
   *                   "value": 1,
   *                   "bg_color": "#f8eadc",
   *                   "ft_color": "#d26c07",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "font_size": "big",
   *                   "font_style": "italic",
   *                   "font_weight": "bold",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               },
   *               {
   *                   "cond_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "condition": "eq",
   *                   "cond_type": "number",
   *                   "value": "",
   *                   "bg_color": "#FFFFFF",
   *                   "ft_color": "#000000",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "num_format"
   *               }
   *           ],
   *           "f_id": "",
   *           "d_id": "",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": true
   *       },
   *       {
   *           "title": "Y",
   *           "rpf_id": "Y",
   *           "data_type": "number",
   *           "display_type": "number",
   *           "align": "right",
   *           "width": 104,
   *           "num_info": {
   *               "prefix": "",
   *               "suffix": "",
   *               "no_comma": false
   *           },
   *           "conditions": [
   *               {
   *                   "cond_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "condition": "eq",
   *                   "cond_type": "number",
   *                   "value": 1,
   *                   "bg_color": "#f8eadc",
   *                   "ft_color": "#d26c07",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "font_size": "big",
   *                   "font_style": "italic",
   *                   "font_weight": "bold",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "comma-separeted"
   *               },
   *               {
   *                   "cond_rpf_id": "5ccad4b2-831a-4172-a91c-aa1f93ff107b",
   *                   "condition": "eq",
   *                   "cond_type": "number",
   *                   "value": "",
   *                   "bg_color": "#FFFFFF",
   *                   "ft_color": "#000000",
   *                   "apply_row": false,
   *                   "use_field_ref": false,
   *                   "value_rpf_id": "",
   *                   "font_size": "normal",
   *                   "font_style": "normal",
   *                   "font_weight": "normal",
   *                   "negative_sign_type": "normal",
   *                   "num_format": "num_format"
   *               }
   *           ],
   *           "f_id": "",
   *           "d_id": "",
   *           "merge_cells": false,
   *           "hide": false,
   *           "disable_search": false,
   *           "use_integrated_report": false,
   *           "is_cross_key": true
   *       }
   *   ]
   * }
   */
  async getReports(token: string, applicationId: string, reportId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/reports/${reportId}/filter`,
      params,
      config
    );
    return result.data;
  }

  /**
   * データレポートの検索条件を取得する
   *
   * データレポートの検索条件を取得する（検索条件ダイアログ等、表示用）
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param reportId 対象のデータレポートID
   * @returns 下記サンプル参照
   *  [
   *     {
   *         "rpf_id": "4a0b2f8a-f332-4579-a2d4-e9b5971d9e80",
   *         "display_id": "TITLE",
   *         "f_id": "5c69148384f4be344c2ff0f1",
   *         "name": "Title",
   *         "dataType": "text",
   *         "order": 0
   *     },
   *     {
   *         "rpf_id": "b752bf7f-9f0d-4370-ac34-45b9be01453f",
   *        "display_id": "DueDate",
   *         "f_id": "5c69148384f4be344c2ff0f2",
   *         "name": "Due Date",
   *         "dataType": "date",
   *         "order": 4
   *     },
   *     {
   *         "rpf_id": "7b9db4bb-3516-4cd7-946a-97c1ddcdca2a",
   *         "display_id": "CATEGORY",
   *         "f_id": "5c6363ed84f4be7de0350452",
   *         "name": "Category",
   *         "dataType": "select",
   *         "order": 2,
   *         "options": [
   *             {
   *                 "o_id": "5c6363f384f4be2550bfe91b",
   *                 "value": "C",
   *                 "color": "",
   *                 "enabled": true,
   *                 "sort_id": 0
   *             },
   *             {
   *                 "o_id": "5c6363f384f4be2550bfe91c",
   *                 "value": "B",
   *                 "color": "",
   *                 "enabled": true,
   *                 "sort_id": 1
   *             },
   *             {
   *                 "o_id": "5c6363f384f4be2550bfe91d",
   *                 "value": "A",
   *                 "color": "",
   *                 "enabled": true,
   *                 "sort_id": 2
   *             }
   *         ]
   *     },
   *     {
   *         "rpf_id": "85c15eca-1b7a-4423-9969-55f363efc4c1",
   *         "name": "Status",
   *         "display_id": "STATUS",
   *         "f_id": "5c6363ed84f4be7de0350453",
   *         "dataType": "status",
   *         "order": 3,
   *         "statuses": [
   *             {
   *                 "s_id": "5c6363ed84f4be7de0350457",
   *                 "name": "New",
   *                 "color": ""
   *             },
   *             {
   *                 "s_id": "5c6363ed84f4be7de0350456",
   *                 "name": "Closed",
   *                 "color": ""
   *             },
   *             {
   *                 "s_id": "5c6363ed84f4be7de0350459",
   *                 "name": "In Review",
   *                 "color": ""
   *             },
   *             {
   *                 "s_id": "5c6363ed84f4be7de0350458",
   *                 "name": "Assigned",
   *                 "color": ""
   *             },
   *             {
   *                 "s_id": "5c6363ed84f4be7de035045a",
   *                 "name": "In Progress",
   *                 "color": ""
   *             }
   *         ]
   *     },
   *     {
   *         "rpf_id": "4552a666-508d-419c-ba99-7c9a16b24894",
   *         "display_id": "NOTE",
   *         "f_id": "5c6459de84f4be4588a77288",
   *         "name": "note",
   *         "dataType": "textarea",
   *         "order": 5
   *     },
   *      {
   *          "rpf_id": "8a20643b-fe36-45f1-bbd6-b2b6e4296a54",
   *          "display_id": "NUM",
   *          "f_id": "5c91f38a84f4be29540c4e9c",
   *          "name": "Num",
   *          "dataType": "number",
   *          "order": 6
   *      }
   *  ]
   */
  async getReportSearchConditions(token: string, applicationId: string, reportId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/applications/${applicationId}/reports/${reportId}/conditions`,
      config
    );
    return result;
  }

  /**
   * アイテム一覧を絞り込むための検索条件（search_conditions) を取得する
   *
   * 検索条件として利用できるフィールドが返る
   * フィールドのdataType(Hexabaseで定義した項目の種類)に依存して、取得されるパラメータが異なる
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @returns 下記サンプル参照
   *  {
   *     "f_id": "5cd3bde184f4be5808a6b3a6", // フィールドID
   *     "name": "タイトル", // フィールド名
   *     "display_id": "Fld-eAXmZhXn", // フィールドの画面ID
   *     "data_type": "text", //データ型
   *     "order": 0 // 表示順
   * },
   */
  async getItemSearchConditions(token: string, applicationId: string, datastoreId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const result = await axios.get(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/conditions`,
      config
    );
    return result.data;
  }

  /**
   * コメントやアクション実行の履歴を取得する
   *
   * @param token Hexabaseのtoken
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @returns 対象アイテムに紐づく履歴データ
   */
  async getItemHistories(token: string, datastoreId: string, itemId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.get(
      `${this.apiDomain}/datastores/${datastoreId}/items/${itemId}/histories`,
      config
    );
    return result.data;
  }

  /**
   * アイテムにコメントを投稿します。
   * コメントはアイテムの履歴へ投稿され、GetItemHistoriesで取得できます。
   *
   * @param token Hexabaseのtoken
   * @param datastoreId 対象のデータストアID
   * @param params 下記サンプル参照
   * {
   *   "project_id": アプリケーションID（p_id）,
   *   "item_id": データアイテムID(i_id),
   *   "comment" : 登録したいコメント
   * }
   * @returns null
   */
  async postItemComment(token: string, datastoreId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.post(
      `${this.apiDomain}/datastores/${datastoreId}/items/histories`,
      params,
      config
    );
    return result;
  }

  /**
   * 関連アイテムとのリンクを追加（DisplayIDの利用）
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @param params 下記サンプル参照
   * {
   *  "link_datastore_id": "DATABASE_2",  // データストアID（Hexabase画面から入力したIDを指定）
   *  "link_item_id": "5d4c055eaa39555618ac9e6a" // 追加するアイテムID
   * }
   * @returns null
   */
  async addItemLink(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/addlink/${itemId}`,
      params,
      config
    );
    return result;
  }

  /**
   * 関連アイテムとのリンクを更新
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @param params 下記サンプル参照
   * {
   *  "old_link_datastore_id": "DATABASE_2", // 更新するリンク先データストアID
   *  "old_link_item_id": "5db16351ef2261da6f3b3560", // 更新するリンク先アイテムID
   *  "new_link_datastore_id": "DATABASE_2", // 追加するデータストアID
   *  "new_link_item_id": "5db16351ef2261da6f3b356b" // 追加するアイテムID
   * }
   * @returns null
   */
  async updateItemLink(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.post(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/updatelink/${itemId}`,
      params,
      config
    );
    return result;
  }

  /**
   * 関連アイテムとのリンクを削除（DisplayIDの利用）
   *
   * @param token Hexabaseのtoken
   * @param applicationId 対象のアプリケーションID
   * @param datastoreId 対象のデータストアID
   * @param itemId 対象のアイテムID
   * @param params 下記サンプル参照
   * {
   *   "link_datastore_id": "DATABASE_2",
   *   "link_item_id": "5d4c055eaa39555618ac9e6a"  //省略すると、link_datastore_idに関連するすべてのItemのリンクが削除されます
   * }
   * @returns null
   */
  async deleteItemLink(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any
  ) {
    const headers = { Authorization: '' };
    headers['Authorization'] = `Bearer ${token}`;

    const result = await axios.delete(
      `${this.apiDomain}/applications/${applicationId}/datastores/${datastoreId}/items/dellink/${itemId}`,
      { headers, data: params }
    );
    return result;
  }

  /**
   * 対象データストア内アイテムを取得します（関連データ含まない）
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param params - アイテム取得用パラメータ
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getItemList(token: string, applicationId: string, datastoreId: string, params: {}) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/search`;
    const result = await this.postApi(uri, params, config);
    return result as ApiResult;
  }

  /**
   * アイテムの詳細情報（関連データ含む）を取得します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getItemDetails(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    includeLinkedItem: boolean,
    includeLookupsItem: boolean,
    format?: string
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const includeLink = includeLinkedItem || false;
    const includeLookups = includeLookupsItem || false;
    const _format = format !== undefined && format.length > 0 ? '&format=map' : '';
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/details/${itemId}?include_linked_items=${includeLink}&include_lookups=${includeLookups}${_format}`;
    const result = await this.getApi(uri, config);
    return result as ApiResult;
  }

  /**
   * アイテムを新規に登録します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param params - データ作成用パラメータ
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async registerItem(token: string, applicationId: string, datastoreId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/new`;
    const result = await this.postApi(uri, params, config);
    return result as ApiResult;
  }

  /**
   * 新規アイテムIDを取得します
   * @param token - ユーザーtoken情報
   * @param datastoreId - 対象データストアID
   * @returns
   * {
   *   "item_id": "5a2647410e24792d87451e34"
   * }
   */
  async createNewItemId(token: string, datastoreId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `datastores/${datastoreId}/items/create-id`;
    const result = await this.postApi(uri, {}, config);
    return result.data.item_id as string;
  }

  /**
   * item_idを指定して新規アイテムを登録します（主にデフォルト登録アクションを利用したい場合）
   * @param token - ユーザーtoken情報
   * @param itemId - アイテムID
   * @param actionId - 対象の新規登録アクションID
   * @param params - {
   *   "item": {
   *    "項目ID1": "作成アイテム項目の値１",
   *      "項目ID2": "作成アイテム項目の値２"
   *      },
   *    "project_id": "プロジェクトID",
   *    "datastore_id": "データベースID",
   *    "use_display_id": true or false //trueの場合、item内の項目IDに画面IDを使用する。falseの場合システム内部のFieldID(f_id)を使用する。指定しない場合、デフォルトはfalse。
   *  }
   * @returns null
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async createItemWithItemId(token: string, itemId: string, actionId: string, params: any) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `items/${itemId}/new-actions/${actionId}`;
    const result = await this.postApi(uri, params, config);
    return result.data;
  }

  /**
   * 対象アイテムの更新を行います
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param params - データ更新用パラメータ
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async editItemData(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/edit/${itemId}`;
    const result = await this.postApi(uri, params, config);
    return result as ApiResult;
  }

  /**
   * 対象アイテムの関連データを取得します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param linkDatastoreId - 関連データストアID
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getLinkedItemList(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    linkDatastoreId: string
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const getLinkdUri = `applications/${applicationId}/datastores/${datastoreId}/items/links/${itemId}?linked_d_id=${linkDatastoreId}&use_display_id=true`;
    const result = await this.getApi(getLinkdUri, config);
    return result as ApiResult;
  }

  /**
   * 指定アイテムを削除します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param linkedDatastoreIdList - 削除対象の関連データストアID群
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async deleteItems(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    linkedDatastoreIdList: string[]
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;

    if (linkedDatastoreIdList.length === 0) {
      config['data'] = {};
    } else {
      config['data'] = { delete_linked_items: true, target_datastores: linkedDatastoreIdList };
    }

    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/delete/${itemId}`;
    const result = await this.deleteApi(uri, config);
    return result as ApiResult;
  }

  /**
   * 指定アクションを実行します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param actionId - 実行アクションID（display_id）
   * @param param - アクション実行時必要パラメーター
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async executeAction(
    token: string,
    applicationId: string,
    datastoreId: string,
    itemId: string,
    actionId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/action/${itemId}/${actionId}`;
    const result = await this.postApi(uri, params, config);
    return result as ApiResult;
  }

  /**
   * 複数アイテムに対して指定アクションを実行します（バルク）
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param actionId - 実行アクションID（display_id）
   * @param param - アクション実行時必要パラメーター
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async executeBulkAction(
    token: string,
    applicationId: string,
    datastoreId: string,
    actionId: string,
    params: any
  ) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/items/bulkaction/${actionId}`;
    const result = await this.postApi(uri, params, config);
    return result as ApiResult;
  }

  /**
   * 自動採番APIに問い合わせします
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param fieldId - データストア内参照連番フィールドのID
   * @param param - 採番ルール { branch_key:付与するSubPrefix{string}, zero_padding:番号をゼロ埋めする{boolean} , digit:桁数{number} }
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getAutoNumberApi(
    token: string,
    applicationId: string,
    datastoreId: string,
    fieldId: string,
    param: { [k: string]: string }
  ) {
    const paramList: string[] = [];
    if (param.branch_key) {
      paramList.push(`branch_key=${param.branch_key}`);
    }
    if (param.zero_padding) {
      paramList.push(`zero_padding=${param.zero_padding}`);
    }
    if (param.digit) {
      paramList.push(`digit=${param.digit}`);
    }
    const getParam = paramList.join('&');
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/fields/${fieldId}/autonum?${getParam}`;
    const result = await this.getApi(uri, config);
    return result as ApiResult;
  }

  /**
   * 指定データストアのフィールド情報を取得します
   * @param token - ユーザーtoken情報
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getDatastoreFields(token: string, applicationId: string, datastoreId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `applications/${applicationId}/datastores/${datastoreId}/fields`;
    const result = await this.getApi(uri, config);
    return result as ApiResult;
  }

  /**
   * 指定データストアの新規登録アクションを取得します
   * @param token - ユーザーtoken情報
   * @param datastoreId - 対象データストアID
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getNewActions(token: string, datastoreId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `datastores/${datastoreId}/new-action`;
    const result = await this.getApi(uri, config);
    return result as ApiResult;
  }

  /**
   * 指定データストアのアクション毎のフィールドルールを取得します
   * @param token - ユーザーtoken情報
   * @param datastoreId - 対象データストアID
   * @param datastoreId - 対象アクションID
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getInputFieldsByActionId(token: string, datastoreId: string, actionId: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const uri = `datastores/${datastoreId}/actions/${actionId}/fields`;
    const result = await this.getApi(uri, config);
    return result as ApiResult;
  }

  /**
   * APIにPOST送信を行う
   * @param { string } targetUri - アクセスするAPIのURI
   * @param { Object } param - APIにセットするパラメーター群
   * @param { string } config - APIにアクセスする際に付与するヘッダー情報
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async postApi(
    targetUri: string,
    params: { [k: string]: string | Array<ApiData> },
    config: { [k: string]: string | any }
  ) {
    const result = await axios.post(`${this.apiDomain}/${targetUri}`, params, config);
    return result as ApiResult;
  }

  /**
   * APIにGET送信を行う
   * @param { string } targetUri - アクセスするAPIのURI
   * @param { string } config - APIにアクセスする際に付与するヘッダー情報
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async getApi(targetUri: string, config: { [k: string]: string | any }) {
    const result = await axios.get(`${this.apiDomain}/${targetUri}`, config);
    return result as ApiResult;
  }

  /**
   * APIにDELETE送信を行う
   * @param { string } targetUri - アクセスするAPIのURI
   * @param { string } config - APIにアクセスする際に付与するヘッダー情報
   * @returns API Object
   * {
   *   data: { [k: string]: string | any }
   *   headers: { [k: string]: string }
   *   status: number
   * }
   */
  async deleteApi(targetUri: string, config: { [k: string]: string | any }) {
    const result = await axios.delete(`${this.apiDomain}/${targetUri}`, config);
    return result as ApiResult;
  }

  /**
   * 指定したグループ情報とその配下のグループ一覧を取得
   * @param token - ユーザーtoken情報
   * @param groupId - グループID（省略すると全グループ情報取得）
   * @returns API Object
   * {
   * "error": "",
   * "group": {
   *     "g_id": "親グループID",
   *     "group_id": "親グループのDISPLAY_ID",
   *     "name": "親グループ名",
   *     "index": 0 //("親グループ位置")
   * },
   * "children": [], //("配下グループのグループID")
   * "count": 0 //（"親グループの配下数"）
   *}
   */
  async getGroup(token: string, groupId?: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const url = groupId === undefined ? 'groups' : `groups/${groupId}`;
    const result = await this.getApi(url, config);
    return result as ApiResult;
  }

  /**
   * 指定したグループ情報とその配下のグループ一覧を取得
   * @param token - ユーザーtoken情報
   * @param groupId - グループID（省略すると全グループ情報取得）
   * @param recursive - 配下の階層を取得するか
   * @returns API Object
   * {
   * "error": "",
   * "group": {
   *     "g_id": "親グループID",
   *     "group_id": "親グループのDISPLAY_ID",
   *     "name": "親グループ名",
   *     "index": 0//("親グループ位置")
   * },
   * "children": [], //("配下グループのグループID")
   * "count": 0 //（"親グループの配下数"）
   *}
   */
  async getUsersInGroup(token: string, groupId: string, recursive?: boolean) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const param = recursive !== undefined ? 'recursive=true' : null;
    let url = `groups/${groupId}/users`;
    if (param !== null) {
      url = url + `?${param}`;
    }
    const result = await this.getApi(url, config);
    return result as ApiResult;
  }

  /**
   * グループツリー情報取得
   * @param token - ユーザーtoken情報
   * @returns API Object
   *{
   *  "error": null,
   *  "result": [
   *    {
   *      "childGroups": [
   *         {
   *           "childGroups": [
   *            {
   *              "childGroups": [],
   *              "display_id": "addfaa",
   *              "id": "5972e630cce5fe6c280cd242",
   *              "name": "test",
   *              "show_child": true
   *            }
   *          ],
   *          "display_id": "ssss",
   *          "id": "5972ebebcce5fe6c280cd246",
   *          "name": "sss",
   *          "show_child": true
   *        },
   *      ],
   *      "display_id": "582b26d8fb90a15e0c24ad81",
   *      "id": "582b26d8fb90a15e0c24ad81",
   *      "name": "WORKSPACE.DEFAULT_GROUP_NAME",
   *      "show_child": true
   *    }
   *  ]
   *}
   */
  async getGroupTree(token: string) {
    const config = JSON.parse(JSON.stringify(defaultConfig));
    config.headers['Authorization'] = `Bearer ${token}`;
    const url = `grouptree`;
    const result = await this.getApi(url, config);
    return result as ApiResult;
  }
}
