/**
 *  ・定義ファイル役割
 *    共通エラーメッセージ
 *  ・概要
 *    画面共通で使用するエラーメッセージを定義
 *  ・更新履歴
 *    2021/05/25 新規作成 佐藤
 */

export const DefaultErrorCode = 'SYSTEM_ERROR';

export const ErrorCode: { [api: string]: { [code: string]: string } } = {
  // ExecuteActionAPIのエラーメッセージ（一括処理でも使用）
  EXECUTEACTION: {
    ITEM_REV_CONFLICT: '他のユーザーがデータを変更したため処理に失敗しました',
    INVALID_VALUE: '登録、更新のために指定されたデータが不正です',
    INVALID_SETTINGS: '設定値にエラーがあるため、処理を継続できません',
    NOT_FOUND: '指定された値が見つかりません',
    PROCESS_STOPPED: '処理が中断されました',
    NO_PRIVILEGES: 'ログインユーザに権限がありません',
    SYSTEM_ERROR: 'システムエラーが発生いたしました'
  },
  // 一括処理で使用する独自定義エラーメッセージ
  BATCH_PROCESSING: {
    // 「ORDER_～」・・・発注一括処理で使用
    ORDER_MAIN_MANDATORY: '発注データに入力不備があります',
    ORDER_DETAIL_MANDATORY: '発注明細データに入力不備があります',
    ORDER_NOT_EXIST: '発注明細データが存在しません',
    ORDER_USER_APPLY: '自分自身が申請したものは承認できません',
    // 「PAYMENT_～」・・・支払依頼一括処理で使用
    PAYMENT_MAIN_MANDATORY: '支払データに入力不備があります',
    PAYMENT_DETAIL_MANDATORY: '支払明細データに入力不備があります',
    PAYMENT_NOT_EXIST: '支払明細データが存在しません',
    PAYMENT_USER_APPLY: '自分自身が申請したものは承認できません',
    // 「PURCHASE_～」・・・購買要件一括処理で使用
    PURCHASE_MAIN_MANDATORY: '購買要件データに入力不備があります',
    PURCHASE_DETAIL_MANDATORY: '購買要件明細データに入力不備があります',
    PURCHASE_NOT_EXIST: '購買要件明細データが存在しません',
    PURCHASE_USER_APPLY: '自分自身が申請したものは承認できません'
  },
  DEFAULT: {
    SYSTEM_ERROR: 'システムエラーが発生いたしました'
  }
};
