/**
 * 例外処理の独自拡張クラス格納ファイル
 */

// 拡張のベース
class BaseError extends Error {
  constructor(e?: string) {
    super(e);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

// アイテムステータスが想定外の際
export class ItemStatusError extends BaseError {}
