// eslint-disable-next-line @typescript-eslint/no-namespace
namespace masterForm {
  export const MASTERCONTROLCOLUMS: { [k: string]: Array<{ [k: string]: string }> } = {
    m_customer: [
      { value: 'id', text: '顧客ID', width: '30%' },
      { value: 'customer_name', text: '顧客名', width: '50%' }
    ],
    m_customer_department: [
      { value: 'id', text: '部署ID', width: '20%' },
      { value: 'department_name', text: '部署名', width: '20%' }
    ],
    m_leasing_company: [
      { value: 'id', text: 'ID', width: '10%' },
      { value: 'leasing_company', text: 'リース会社名', width: '40%' },
      { value: 'telephone_number', text: '電話番号', width: '50%' }
    ],
    m_rate: [{ value: 'id', text: 'ID', width: '10%' }],
    m_employee: [
      { value: 'id', text: '社員ID', width: '20%' },
      { value: 'name', text: '社員名', width: '20%' }
    ],
    m_service_type: [
      { value: 'id', text: 'ID', width: '20%' },
      { value: 'service_type', text: 'サービス種別', width: '50%' }
    ],
    m_commodity: [
      { value: 'id', text: 'ID', width: '20%' },
      { value: 'commodity', text: '商材', width: '30%' }
    ],
    t_bt6_document_category: [
      { value: 'category_id', text: 'ID', width: '20%' },
      { value: 'category_name', text: 'カテゴリ名', width: '30%' }
    ],
    m_department: [
      { value: 'id', text: '部署ID', width: '20%' },
      { value: 'name', text: '部署名', width: '20%' }
    ]
  };
}

export const masterControlColums = masterForm.MASTERCONTROLCOLUMS;

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace formDefine {
  // APIから取得したDatatypeと使用するべきモジュールコンポーネントをマッピング用
  export const MAPPING: { [k: string]: { component: string; props: string[] } } = {
    text: {
      component: 'InputArea',
      props: ['labelName', 'labelText', 'name']
    },
    number: {
      component: 'InputArea',
      props: ['labelName', 'labelText', 'name', 'inputType']
    },
    select: {
      component: 'SelectArea',
      props: ['label', 'selectData', 'name']
    },
    textarea: {
      component: 'TextArea',
      props: ['labelName', 'labelText', 'name']
    },
    datetime: {
      component: 'DatePickerArea',
      props: ['labelName', 'labelText', 'name']
    },
    radio: {
      component: 'RadioArea',
      props: ['label', 'selectData', 'name']
    },
    dslookup: {
      component: 'MasterSelectControl',
      props: ['leftSideInputName', 'dialogTitle', 'label', 'isRightSideInputReadOnly']
    },
    status: {
      component: 'SelectArea',
      props: ['label', 'selectData', 'name']
    },
    file: {
      component: 'FileManager',
      props: ['label', 'selectData', 'name']
    },
    autonum: {
      component: 'InputArea',
      props: ['labelName', 'labelText', 'name']
    }
  };
  // 使用するモジュールコンポーネントの各propsにセットするAPIの返り値をマッピング
  export const settingPropsValue: { [k: string]: string } = {
    labelName: 'display_id',
    labelText: 'name',
    label: 'name',
    name: 'display_id',
    selectData: 'options',
    inputType: 'dataType'
  };
}

export const formMapDefineData = formDefine.MAPPING;
export const formMapPropsDefineData = formDefine.settingPropsValue;

// 帳票関連
export const formFormatReplaceStr = 'is_output_';

// ファイル関連
export const fileActionMasterDefuneName = {
  delete: {
    success: 'FILE_DELETE_SUCCESS_MESSAGE',
    fail: 'FILE_DELETE_FAIL_MESSAGE'
  },
  upload: {
    success: 'FILE_UPLOAD_SUCCESS_MESSAGE',
    fail: 'FILE_UPLOAD_FAIL_MESSAGE'
  }
};
export const fileActionDefautMessage = {
  delete: {
    success: 'ファイルを削除しました。',
    fail: 'ファイルの削除に失敗しました。'
  },
  upload: {
    success: 'ファイルを登録しました。',
    fail: 'ファイルの登録に失敗しました。'
  }
};
export const buttonLabel: { [k: string]: string } = {
  delete: '削除',
  download: 'ダウンロード'
};
export const tableColumn: { [k: string]: any } = {
  columns: [
    { text: 'ファイル名', value: 'name' },
    { text: '', value: 'file_id', sortable: false, width: 70 }
  ],
  buttonItems: [
    {
      name: 'file_id',
      text: '',
      icon: 'mdi-delete-outline',
      alwaysDisplayed: true,
      class: 'btn-icon--noframe',
      disabled: true
    }
  ]
};
