/**
 * Docurain関連の定数ファイル
 */

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace FormSetting {
  export const previewName = 'preview';
  export const inputNames = {
    type: 'form_type',
    format: 'output_format',
    templateName: 'template_name'
  };
  export const initialSearchCondition = ['scene', 'is_active'];

  export const orderSearchScene = 'order_sent';
}
export const previewName = FormSetting.previewName;
export const inputNames = FormSetting.inputNames;
export const initialSearchCondition = FormSetting.initialSearchCondition;
export const orderSearchScene = FormSetting.orderSearchScene;

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace DocurainSetting {
  export const contentType: { [k: string]: string } = {
    pdf: 'application/pdf',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  };
  export const mapFormat: { [k: string]: string } = {
    pdf: 'pdf',
    excel: 'xlsx'
  };
}
export const contentType = DocurainSetting.contentType;
export const mapFormat = DocurainSetting.mapFormat;
