/**
 *  ・クラス名
 *    共通モデル
 *  ・概要
 *    画面共通で使用可能な処理を記述
 *  ・更新履歴
 *    2021/01/15 新規作成 富本
 *    2021/01/29 動的フォーム処理関連処理追加 佐藤
 *    2021/03/11 ワークフロー関連処理追加 佐藤
 *    2021/08/18 帳票出力関連処理追加
 */
import { DateTime } from 'luxon';
import HexabaseApi from '@/services/hexabase/hexabaseApi';
import Docurain from '@/services/docurain/docurain';
import hexabaseState from '@/store/hexabase';
import authState from '@/store/auth';
import appState from '@/store/app';
import {
  ActionFieldSetting,
  GetItemDetailsResponse,
  CreateItemResponse,
  UpdateItemResponse,
  DeleteItemResponse,
  ExecuteActionResponse,
  GetAutoNumberResponse,
  DatastoreFields,
  FieldChildrenProp,
  FieldsInfo,
  DatastoreActons,
  GetInputFieldsActions,
  GetItemsResponse,
  GetItemSearch,
  StatusActions,
  ItemActions,
  ActionButtons,
  GetReportsResponse,
  SearchConditions
} from '@/services/hexabase/types';
import {
  masterControlColums,
  formMapDefineData,
  formMapPropsDefineData,
  formFormatReplaceStr
} from '@/constants/form/form';
import { ErrorCode, DefaultErrorCode } from '@/constants/error';
import { errorConf } from '@/constants/util';
import { SelectProps, OutPutFormRules, InputRules } from '@/constants/form/types';
import {
  mapFormat,
  contentType,
  initialSearchCondition,
  inputNames
} from '@/constants/docurain/docurain';
import { ItemStatusError } from '@/models/lib/customError';
import Encoding from 'encoding-japanese';

/* eslint-disable @typescript-eslint/camelcase */
export interface Payload {
  fields?: [];

  conditions?: {
    id: string;
    search_value: Array<string>;
  }[];
  sort_fields?: [
    {
      id: string;
      order: 'desc' | 'asc';
    }
  ];
  per_page?: number;
  page?: number;
  use_display_id?: boolean;
}

interface Rules {
  id: string;
  rule: string[];
  referenceField?: {
    id: string;
    rule: string;
    addValue?: boolean;
  }[];
}

export default class BaseModel {
  fields?: any; // 各ドメイン、マスタモデル項目に合わせてサブクラスで上書き
  detailFields?: any;

  payload?: Payload;
  private hexaApi = new HexabaseApi();
  private docurain = new Docurain();
  // API レスポンス型不正時エラー番号
  private typeDifferentCode = '001';

  // CSV出力ファイル拡張子
  private csvFileSuffix = 'csv';

  constructor(payload?: Payload) {
    const defaultPayload = {
      conditions: [],
      per_page: 0,
      page: 0,
      use_display_id: true
    };
    this.payload = payload ? payload : defaultPayload;
  }

  // 一括処理を行う際のAPIパラメータ
  public bulkActionParam = {
    conditions: [
      {
        id: 'i_id',
        search_value: [] as string[]
      }
    ],
    use_display_id: true,
    comment: '一括処理'
  };

  /**
   * アイテムの詳細情報（関連データ含む）を取得します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param email - HexabaseアカウントEメール
   * @param password - Hexabaseアカウントパスワード
   * @returns { token } HexabaseAPIリクエスト用トークン
   */
  public async login(email: string, password: string) {
    try {
      const token = await this.hexaApi.login(email, password);
      return token;
    } catch (error) {
      if (error instanceof Error) throw new Error(error.message);
    }
  }

  /**
   * アイテムの詳細情報（関連データ含む）を取得します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @returns {
   *      "title": "タスクE",
   *      "field_values": {
   *          "AutoNo": {
   *              "field_id": "AutoNo",
   *              "field_name": "AutoNo",
   *              "dataType": "autonum",
   *              "value": "21",
   *              "use_as_search": true,
   *              "show_in_list": true
   *          },
   *      },
   *      "status_list": {
   *          "5cd3bde284f4be5808a6b3ac": {
   *              "status_id": "5cd3bde284f4be5808a6b3ac",
   *              "status_name": "完了"
   *          },
   *      },
   *      "status_actions": {
   *          "5cd3bde284f4be5808a6b3b1": {
   *              "action_id": "5cd3bde284f4be5808a6b3b1",
   *              "action_name": "次のステータスに進める",
   *              "display_order": 0,
   *              "crud_type": "2",
   *              "next_status_id": "5cd3bde284f4be5808a6b3ae"
   *          }
   *      },
   *      "item_actions": {
   *          "5cd3bde384f4be5808a6b3b9": {
   *              "action_id": "5cd3bde384f4be5808a6b3b9",
   *              "action_name": "内容を更新する",
   *              "display_order": 0,
   *              "crud_type": "2"
   *          },
   *      },
   *      "linked_items": {
   *          "ChildDB1": {
   *              "d_id": "5cc25d1e84f4be574418d580",
   *              "ds_name": "ChildDB1",
   *              "display_id": "ChildDB1",
   *              "fields": {
   *                  "Fld-1hfacFJP": {
   *                      "access_keys": [
   *                          "5c6363da84f4be7de035044a",
   *                          "5c6363da84f4be7de035044c",
   *                          "5ca5561484f4be19cc01d378",
   *                          "5ca5562084f4be19cc01d37a"
   *                      ],
   *                      "as_title": false,
   *                      "calc_info": {
   *                          "calc_target_fields": [
   *                              "5cc4dbc084f4be926c491d97",
   *                              "5cc4dbcd84f4be926c491d98"
   *                          ],
   *                          "formula": "{Fld-xGx9u6A2}   /   {Fld-fAcrbFfs}+{Fld-xGx9u6A2}",
   *                          "no_comma": false,
   *                          "prefix": "\\",
   *                          "suffix": ""
   *                      },
   *                      "d_id": "5cc25d1e84f4be574418d580",
   *                      "dataType": "calc",
   *                      "display_id": "Fld-1hfacFJP",
   *                      "f_id": "5cc8fa5484f4be926c491d9a",
   *                      "fieldIndex": 0,
   *                      "field_csv_name": "",
   *                      "full_text": false,
   *                      "hideOnInput": false,
   *                      "id": "5cc8fa5484f4be926c491d9a",
   *                      "max_value": "",
   *                      "min_value": "",
   *                      "name": "計算式フィールド",
   *                      "names": {
   *                          "en": "計算式フィールド",
   *                          "ja": "計算式フィールド"
   *                      },
   *                      "p_id": "5c6363d984f4be7de0350445",
   *                      "search": true,
   *                      "show_list": true,
   *                      "status": false,
   *                      "title_order": 0,
   *                      "unique": false,
   *                      "w_id": "5c5fa7da84f4be4250aaee27"
   *                  },
   *              },
   *              "items": []
   *          },
   *          "FieldTypeLineItems": {
   *              "d_id": "5c6d32f784f4be1f241ff9ff",
   *              "ds_name": "フィールドType明細",
   *              "display_id": "FieldTypeLineItems",
   *              "fields": [
   *                  "FK": {
   *                      "access_keys": [
   *                          "5c6363da84f4be7de035044a",
   *                          "5c6363da84f4be7de035044c"
   *                      ],
   *                      "as_title": true,
   *                      "d_id": "5c6d32f784f4be1f241ff9ff",
   *                      "dataType": "text",
   *                      "display_id": "FK",
   *                      "f_id": "5c6d32f784f4be70b8bd20b9",
   *                      "fieldIndex": 0,
   *                      "field_csv_name": "",
   *                      "full_text": true,
   *                      "hideOnInput": false,
   *                      "id": "5c6d32f784f4be70b8bd20b9",
   *                      "max_value": "",
   *                      "min_value": "",
   *                      "name": "外部キー",
   *                      "names": {
   *                          "en": "FK",
   *                          "ja": "外部キー"
   *                      },
   *                      "p_id": "5c6363d984f4be7de0350445",
   *                      "search": true,
   *                      "show_list": true,
   *                      "status": false,
   *                      "title_order": 3,
   *                      "unique": false,
   *                      "w_id": "5c5fa7da84f4be4250aaee27"
   *                  },
   *              ],
   *              "items": [
   *                  {
   *                      "FK": "タスクE",
   *                      "Fld-677Ld02p": "h.iwasaki,BeeBot",
   *                      "Fld-BLoaNKjo": "A",
   *                      "Fld-NdC29Im0": "2015-12-31T15:00:00Z",
   *                      "Fld-d0g8UE7D": "X",
   *                      "Fld-xjKEyppI": "確認",
   *                      "Fld-zto1YlDw": "AAAAA,DDDDD",
   *                      "created_at": "2019-04-22T08:05:45Z",
   *                      "created_by": "IMPORT",
   *                      "d_id": "5c6d32f784f4be1f241ff9ff",
   *                      "i_id": "5cbd75d984f4bef6c0ed8591",
   *                      "labels": "",
   *                      "p_id": "5c6363d984f4be7de0350445",
   *                      "rev_no": "2",
   *                      "status_id": "5c6d32f884f4be70b8bd20c2",
   *                      "title": "タスクE",
   *                      "updated_at": "2020-05-23T07:48:16Z",
   *                      "updated_by": "5c5fa7aa84f4be4250aaee25"
   *                  }
   *              ]
   *          }
   *      }
   *  }
   */
  public getLinkedItems(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    format?: string
  ) {
    return this.hexaApi
      .getItemDetails(authState.token, applicationId, datastoreId, itemId, true, true, format)
      .then(response => {
        if (format !== undefined) {
          return response.data;
        } else if (this.isItemDetailType(response.data)) {
          return response.data;
        } else {
          throw new Error(this.makeException(this.typeDifferentCode));
        }
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        let errorCode = '';
        switch (error.response.status) {
          case 404:
            errorCode = error.response.data.errors[0].error_code;
            break;
          case 500:
            errorCode = error.response.data.error_code;
            break;
          default:
            errorCode = this.getErrMessage('DEFAULT', DefaultErrorCode);
            break;
        }
        throw this.getMessage('GETITEMDETAILS', errorCode);
      });
  }

  public getItemDetails(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    format?: string
  ) {
    return this.hexaApi
      .getItemDetails(authState.token, applicationId, datastoreId, itemId, false, false, format)
      .then(response => {
        if (format !== undefined) {
          return response.data;
        } else if (this.isItemDetailType(response.data)) {
          return response.data;
        } else {
          throw new Error(this.makeException(this.typeDifferentCode));
        }
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        let errorCode = '';
        switch (error.response.status) {
          case 404:
            errorCode = error.response.data.errors[0].error_code;
            break;
          case 500:
            errorCode = error.response.data.error_code;
            break;
          default:
            errorCode = this.getErrMessage('DEFAULT', DefaultErrorCode);
            break;
        }
        throw this.getMessage('GETITEMDETAILS', errorCode);
      });
  }

  public getLinked(applicationId: string, datastoreId: string, itemId: string, linkDb: string) {
    return this.hexaApi
      .getLinkedItemList(authState.token, applicationId, datastoreId, itemId, linkDb)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  public getUserInfo() {
    return this.hexaApi
      .getUserInfo(authState.token)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  public getWorkspaces() {
    return this.hexaApi
      .getWorkspaces(authState.token)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  public getApplications() {
    return this.hexaApi
      .getApplications(authState.token, hexabaseState.workspaceId)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * アイテム・関連データを新規に登録します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param params - データ作成用パラメータ
   * @returns {
   *      "error": null,
   *      "history_id": "5d661782aa39559a80479492",
   *      "item_id": "5a2647410e24792d87451e34",
   *      "item": {
   *         // Payload に "return_item_result": true を指定した場合、登録されたアイテム情報がもどる
   *      }
   *  }
   */
  public registerLinkedItems(applicationId: string, datastoreId: string, params: any) {
    return this.hexaApi
      .registerItem(authState.token, applicationId, datastoreId, params)
      .then(response => response.data as CreateItemResponse)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        return {
          ...error.response.data,
          ...{
            status: error.response.status,
            statusText: error.response.statusText
          }
        };
      });
  }

  /**
   * 対象アイテム・関連データの更新を行います
   * 関連データは既存データを削除し新たにインサートします
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param params - データ更新用パラメータ
   * @param linkDatastoreId - 関連データストアID
   * @returns {
   *    item: {
   *      // 登録されたアイテム情報がもどる（関連アイテムの詳細は含まれない）
   *    }
   *  }
   */
  updateLinkedItems(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    params: any,
    linkDatastoreId: string
  ) {
    // 関連データを対象データストアに問い合わせ後削除処理
    return this.hexaApi
      .getLinkedItemList(authState.token, applicationId, datastoreId, itemId, linkDatastoreId)
      .then(response => {
        const linkData: Array<{ [k: string]: string }> =
          response.data.result[linkDatastoreId].items;
        if (linkData && linkData.length !== 0) {
          const linkItems: string[] = linkData.map(item => {
            return item.i_id;
          });
          linkItems.forEach(itemId => {
            params.related_ds_items[linkDatastoreId].push({ operation: 3, i_id: itemId });
          });
        }

        return this.hexaApi
          .editItemData(authState.token, applicationId, datastoreId, itemId, params)
          .then(response => {
            return response.data as UpdateItemResponse;
          });
      });
  }

  /**
   * 対象アイテムの更新を行います
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param params - データ更新用パラメータ
   * @returns API Object { [k: string]: string | any }
   */
  public updateItems(applicationId: string, datastoreId: string, itemId: string, params: any) {
    return this.hexaApi
      .editItemData(authState.token, applicationId, datastoreId, itemId, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /**
   * 指定アイテム及び関連データを削除します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param linkedDatastoreIdList - 削除対象の関連データストアID群
   * @returns {
   *      "error": null,
   *  }
   */
  public deleteLinkedItems(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    linkedDatastoreIdList: string[]
  ) {
    return this.hexaApi
      .deleteItems(authState.token, applicationId, datastoreId, itemId, linkedDatastoreIdList)
      .then(response => response.data as DeleteItemResponse)
      .catch(error => {
        throw new Error(error.message);
      });
  }

  public deleteLinked(applicationId: string, datastoreId: string, itemId: string, param: any) {
    return this.hexaApi
      .deleteItemLink(authState.token, applicationId, datastoreId, itemId, param)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * 指定したアイテムのステータスを進めます
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @param actionId - 実行アクションID（display_id）
   * @param param - アクション実行時必要パラメーター  ※必須「rev_no」
   * TODO: レスポンス型指定についてはAPI改修後反映
   */
  public changeStatus(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    actionId: string,
    params: any
  ) {
    return this.hexaApi
      .executeAction(authState.token, applicationId, datastoreId, itemId, actionId, params)
      .then(response => response.data as ExecuteActionResponse)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        return {
          ...error.response.data,
          ...{
            status: error.response.status,
            statusText: error.response.statusText
          }
        };
      });
  }

  /**
   * 指定したアイテムのステータスを進めます
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param actionId - 実行アクションID（display_id）
   * @param param -
   * TODO: レスポンス型指定についてはAPI改修後反映
   */
  public execBulkAction(applicationId: string, datastoreId: string, actionId: string, params: any) {
    return this.hexaApi
      .executeBulkAction(authState.token, applicationId, datastoreId, actionId, params)
      .then(response => response.data)
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * 自動採番APIに問い合わせします
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param fieldId - データストア内参照連番フィールドのID
   * @param param - 採番ルール { branch_key:付与するSubPrefix{string}, zero_padding:番号をゼロ埋めする{boolean} , digit:桁数{number} }
   * @returns {
   *    "has_error": false,
   *      "result": {
   *        "number": 1,
   *        "value": "A00001"
   *    }
   * }
   */
  public getAutoNumber(
    applicationId: string,
    datastoreId: string,
    fieldId: string,
    param: { [k: string]: string }
  ) {
    return this.hexaApi
      .getAutoNumberApi(authState.token, applicationId, datastoreId, fieldId, param)
      .then(response => response.data as GetAutoNumberResponse)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getMessage('AUTONUMBER', error.response.data.errors[0].error_code);
      });
  }

  /**
   * 特定アイテムの次ステータスリストを取得します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param itemId - 対象アイテムID
   * @returns [
   *    "5cd3bde284f4be5808a6b3b1": {
   *        "action_id": "5cd3bde284f4be5808a6b3b1",
   *        "action_name": "次のステータスに進める",
   *        "display_order": 0,
   *        "crud_type": "2",
   *        "next_status_id": "5cd3bde284f4be5808a6b3ae"
   *    }
   *  ]
   */
  public getExecutableStatusAction(applicationId: string, datastoreId: string, itemId: string) {
    return this.hexaApi
      .getItemDetails(authState.token, applicationId, datastoreId, itemId, false, true)
      .then(response => {
        if (this.isItemDetailType(response.data)) {
          return response.data.status_actions && response.data.status_actions.length !== 0
            ? response.data.status_actions
            : [];
        } else {
          throw new Error(this.makeException(this.typeDifferentCode));
        }
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        let errorCode = '';
        switch (error.response.status) {
          case 404:
            errorCode = error.response.data.errors[0].error_code;
            break;
          case 500:
            errorCode = error.response.data.error_code;
            break;
          default:
            errorCode = this.getErrMessage('DEFAULT', DefaultErrorCode);
            break;
        }
        throw this.getMessage('GETITEMDETAILS', errorCode);
      });
  }

  /**
   * 指定データストアのフィールド情報を取得します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param applicationId - アプリケーションID
   * @param datastoreId - 対象データストアID
   * @returns {
   *    "fields": {
   *        "59bf42550e247918255de00d": {
   *            "field_id": "59bf42550e247918255de00d",
   *            "name": "タイトル",
   *            "display_id": "タイトル",
   *            "dataType": "text",
   *            "search": true,
   *            "show_list": false,
   *            "as_title": true,
   *            "status": false,
   *            "fieldIndex": 0,
   *            "title_order": 1,
   *            "full_text": false,
   *            "unique": false,
   *            "min_value": "",
   *            "max_value": ""
   *          },
   *        },
   *        "5a8392390e247948bd5cfaf3": {
   *          "field_id": "5a8392390e247948bd5cfaf3",
   *          "name": "s1",
   *          "display_id": "Fld-FZR9lYNR",
   *          "dataType": "select",
   *          "search": true,
   *          "show_list": true,
   *          "as_title": false,
   *          "status": false,
   *          "fieldIndex": 0,
   *          "title_order": 0,
   *          "full_text": false,
   *          "unique": false,
   *          "min_value": "",
   *          "max_value": "",
   *          "options": [
   *            {
   *              "option_id": "dc236a0d-58b0-400e-88c7-9276207438cb",
   *              "sort_id": 0,
   *              "value": "選択1",
   *              "enabled": true
   *            },
   *            {
   *              "option_id": "a355531b-c80f-40e8-b6d7-3dc75cc9f3c5",
   *              "sort_id": 1,
   *              "value": "選択2",
   *              "enabled": true
   *            }
   *          ]
   *        }
   *    },
   *    "field_layout": {
   *        "59bf42550e247918255de00d": {
   *            "field_id": "59bf42550e247918255de00d",
   *            "sizeX": 9,
   *            "sizeY": 1,
   *            "col": 0,
   *            "row": 0
   *        },
   *        "5a26722e0e24794c979fa5b6": {
   *            "field_id": "5a26722e0e24794c979fa5b6",
   *            "sizeX": 6,
   *            "sizeY": 1,
   *            "col": 0,
   *            "row": 1
   *        },
   *    }
   * }
   */
  public getFieldsData(applicationId: string, datastoreId: string) {
    return this.hexaApi
      .getDatastoreFields(authState.token, applicationId, datastoreId)
      .then(response => response.data as DatastoreFields)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getErrMessage('GETDATASTOREFIELDS', error.response.data);
      });
  }

  /**
   * 特定データストアの新規登録アクションを取得します
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * 注意：Datastoreの項目ID使用不可
   * @param datastoreId - 対象データストアID
   * @returns [
   *    "actions": [
   *        {
   *            "action_id": "5a2502f00e24792b67887200",  // アクションID
   *            "display_order": 2,  // メニューの表示順。HexabaseのActionメニューのソート順
   *            "crud_type": "1",  // 常に1（新規登録）が返る
   *            "next_status_id": "5a2502f00e24792b678871f8"   //アクション実行後、遷移するステータスID
   *        }
   *    ]
   */
  public getNewActionList(datastoreId: string) {
    return this.hexaApi
      .getNewActions(authState.token, datastoreId)
      .then(response => response.data as DatastoreActons)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getMessage('GETNEWACTIONS', error.response.data.error_code);
      });
  }

  /**
   * 特定データストアのアクションで利用可能なフィールド情報を取得する
   * それぞれ「新規」「更新」にて必須項目・項目の表示等が変わるため
   * 画面項目毎の設定を取得する
   * エラーについて：
   * 型不正・サーバーエラー等が発生した場合はエクセプションを投げるため
   * 参照元で try/catch にてエラーハンドリングを行ってください
   * @param datastoreId - 対象データストアID
   * @param actionId    - アクションID
   * @returns {
   *    "action": {
   *        "action_id": "5a2671ec0e24794c979fa5b1",
   *        "display_order": 3,
   *        "description": "",
   *        "crud_type": "2",
   *        "next_status_id": "5a2671eb0e24794c979fa5ab"
   *    },
   *    "action_fields": {
   *        "5a2671eb0e24794c979fa5a9": {
   *            "field_id": "5a2671eb0e24794c979fa5a9",
   *            "name": "タイトル",
   *            "display_id": "Fld-U2WtgeXa",
   *            "dataType": "text",
   *            "search": true,
   *            "show_list": false,
   *            "as_title": true,
   *            "status": false,
   *            "fieldIndex": 0,
   *            "title_order": 1,
   *            "full_text": false,
   *            "unique": false,
   *            "min_value": "",
   *            "max_value": ""
   *        }
   *    },
   *    "action_field_settings": {
   *      "5a2671eb0e24794c979fa5a9": {
   *          "action_id": "5a2671ec0e24794c979fa5b1",
   *          "field_id": "5a2671eb0e24794c979fa5a9",
   *          "show": true,
   *          "update": true,
   *          "mandatory": false
   *      }
   *    }
   *  }
   */
  public getFieldsByActionId(datastoreId: string, actionId: string) {
    return this.hexaApi
      .getInputFieldsByActionId(authState.token, datastoreId, actionId)
      .then(response => response.data as GetInputFieldsActions)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getMessage('GETINPUTFIELDS', error.response.data.error);
      });
  }

  /**
   * 特定データストアからアイテム情報取得
   * @param applicationId - 対象アプリケーションID
   * @param dataStoreId - 対象データストアID
   * @returns {
   *      "items": [
   *          {
   *              "58bbaa27fbfcba773851339f": "fadfa",
   *              "58bbaa27fbfcba77385133a2": "新規",
   *              "_id": "596e2327fbfcbab8283dde09",
   *              "a_id": "58bbaa27fbfcba77385133ac",
   *              "access_keys": "",
   *              "created_at": "2017-07-19T00:03:06+09:00",
   *              "created_by": "58272f4efb90a148d8508d9c",
   *              "d_id": "58bbaa27fbfcba6098746061",
   *              "i_id": "596e2327fbfcbab8283dde09",
   *              "p_id": "586f6c2ccce5fe6ad071b1d4",
   *              "rev_no": "1",
   *              "status_id": "58bbaa27fbfcba77385133a4",
   *              "title": "fadfa",
   *              "unread": "0"
   *          }
   *      ],
   *      "totalItems": 2
   *  }
   */
  public getItemList(applicationId: string, dataStoreId: string, params: any) {
    return this.hexaApi
      .getItems(authState.token, applicationId, dataStoreId, params)
      .then(response => {
        if (this.isExsist(response.errors) && response.errors.length > 0) {
          throw response;
        }
        return response.items as Array<{ [k: string]: any }>;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        if (error.response !== undefined && error.response.status !== undefined) {
          error = error.response.data;
        }
        throw this.getErrMessage('ITEMLIST', error);
      });
  }

  public getItemCount(applicationId: string, dataStoreId: string, params: any) {
    return this.hexaApi
      .getItemCount(authState.token, applicationId, dataStoreId, params)
      .then(response => {
        return response as Array<{ [k: string]: any }>;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  public getItemSearch(applicationId: string, dataStoreId: string, params: any) {
    return this.hexaApi
      .getItemList(authState.token, applicationId, dataStoreId, params)
      .then(response => {
        if (
          response.data.errors &&
          response.data.errors[0] &&
          !['SEARCH_TIMEOUT', 'COUNT_TIMEOUT'].includes(response.data.errors[0].error_code)
        ) {
          throw this.getMessage('ITEMLIST', response.data.errors[0].error_code);
        }
        return response.data as GetItemsResponse;
      })
      .catch(error => {
        // status200 の場合はエラーメッセージを拾うためそのまま例外を投げる
        if (typeof error === 'string') {
          throw error;
        }
        hexabaseState.setApiStatus(error.response.status);
        const errorCode = error.response.data.error_code
          ? error.response.data.error_code
          : ErrorCode.DEFAULT.SYSTEM_ERROR;
        throw this.getMessage('ITEMLIST', errorCode);
      });
  }

  public getItemSearchConditions(applicationId: string, dataStoreId: string) {
    return this.hexaApi
      .getItemSearchConditions(authState.token, applicationId, dataStoreId)
      .then(response => {
        return response as GetItemSearch;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getMessage('GETITEMSEARCHCONDITIONS', error.response.data.errors[0].error);
      });
  }

  public getItemSearchCsv(applicationId: string, dataStoreId: string, params: any, fileName: string, encode?: Encoding.Encoding) {
    return this.hexaApi
      .getItemList(authState.token, applicationId, dataStoreId, params)
      .then(response => {
        if(encode !== undefined) {
          // 文字コード変換(UNICODE->SJIS)
          const codeArray = Encoding.stringToCode(response.data.toString());
          const sjisArray = Encoding.convert(codeArray, encode, 'UNICODE');
          const uInt8List = new Uint8Array(sjisArray);
          // 取得したCSVデータをダウンロード
          this.formOutPutDownLoad(uInt8List, fileName, this.csvFileSuffix);
        } else {
          // UNICODEのままの場合
          this.formOutPutDownLoad(response.data, fileName, this.csvFileSuffix);
        }
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /**
   * 特定データレポートからアイテム情報取得
   * @param applicationId - 対象アプリケーションID
   * @param reportId - 対象データレポートID
   * @param params - 検索条件
   * @returns {
   *      "items": [
   *          {
   *              "58bbaa27fbfcba773851339f": "fadfa",
   *              "58bbaa27fbfcba77385133a2": "新規",
   *              "_id": "596e2327fbfcbab8283dde09",
   *              "a_id": "58bbaa27fbfcba77385133ac",
   *              "access_keys": "",
   *              "created_at": "2017-07-19T00:03:06+09:00",
   *              "created_by": "58272f4efb90a148d8508d9c",
   *              "d_id": "58bbaa27fbfcba6098746061",
   *              "i_id": "596e2327fbfcbab8283dde09",
   *              "p_id": "586f6c2ccce5fe6ad071b1d4",
   *              "rev_no": "1",
   *              "status_id": "58bbaa27fbfcba77385133a4",
   *              "title": "fadfa",
   *              "unread": "0"
   *          }
   *      ],
   *      "totalItems": 2
   *  }
   */
  public getReports(applicationId: string, reportId: string, params: any) {
    return this.hexaApi
      .getReports(authState.token, applicationId, reportId, params)
      .then(response => {
        return response as GetReportsResponse;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw this.getErrMessage('GETREPORTDATABYCONDITIONS', error.response.data);
      });
  }

  public getReportSearchConditions(applicationId: string, reportId: string) {
    return this.hexaApi
      .getReportSearchConditions(authState.token, applicationId, reportId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  public getReportsCsv(applicationId: string, reportId: string, params: any, fileName: string, filter?:(data: any) => any, encode?: Encoding.Encoding) {
    return this.hexaApi
      .getReports(authState.token, applicationId, reportId, params)
      .then(response => {
        let data:any;

        // フィルターが定義されている場合は、フィルター処理を実行
        if(filter !== undefined) {
          data = filter(response);
        } else {
          data = response;
        }
        if(encode !== undefined) {
          // 文字コード変換(UNICODE->SJIS)
          const codeArray = Encoding.stringToCode(data.toString());
          const sjisArray = Encoding.convert(codeArray, encode, 'UNICODE');
          const uInt8List = new Uint8Array(sjisArray);
          // 取得したCSVデータをダウンロード
          this.formOutPutDownLoad(uInt8List, fileName, this.csvFileSuffix);
        } else {
          // UNICODEのままの場合
          this.formOutPutDownLoad(data, fileName, this.csvFileSuffix);
        }
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /***
   * 以下入力フォーム動的生成メソッド
   * データストアのフィールド情報を取得し
   * HexaBaseUIで設定した入力フォームを画面上で入力フォームとして再現する
   */

  /**
   * 動的生成コンポーネントに渡すオブジェクト（設定）を生成
   * @param applicationId - 対象アプリケーションID
   * @param datastoreId - 対象データストアID
   * @param curentData - 登録済みアイテム情報 入力値に反映させる場合使用
   * @returns 各フィールド情報を含んだ配列
   * [
   *  {
   *    as_title: true
   *    col: 0
   *    component: "InputArea"
   *    dataType: "text"
   *    display_id: "order_application_no"
   *    fieldIndex: 0
   *    field_id: "60050d23eb0c9000012d15ee"
   *    full_text: false
   *    max_value: ""
   *    min_value: ""
   *    name: "発注申請No"
   *    props: {__ob__: Observer}
   *    row: 0
   *    search: true
   *    show_list: true
   *    sizeX: 2
   *    sizeY: 1
   *    status: false
   *    title_order: 4
   *    unique: false
   *  }
   *]
   */
  makeFieldsList(applicationId: string, datastoreId: string, curentData?: Array<FieldsInfo>) {
    // 該当データストアのフィールドデータを取得
    return this.getFieldsData(applicationId, datastoreId).then(response => {
      const result: any = [];
      // 各フィールド情報を取得し適用するモジュールコンポーネントとprops情報を追加
      Object.keys(response.fields).forEach(key => {
        const copyObj = Object.create(response);
        const fieldType = copyObj.fields[key].dataType;
        if (fieldType in formMapDefineData) {
          copyObj.fields[key]['component'] = formMapDefineData[fieldType].component;
          copyObj.fields[key]['props'] = this.makeProps(
            formMapDefineData[fieldType].props,
            copyObj.fields[key],
            curentData
          );
          // レイアウト情報をセットする
          if (key in copyObj.field_layout) {
            copyObj.fields[key] = { ...copyObj.fields[key], ...copyObj.field_layout[key] };
          }
          result.push(copyObj.fields[key]);
        }
      });
      return this.getLayoutKeySort(result);
    });
  }

  public biludFields(response: DatastoreFields, curentData?: Array<FieldsInfo>) {
    const result: any = [];
    // 各フィールド情報を取得し適用するモジュールコンポーネントとprops情報を追加
    Object.keys(response.fields).forEach(key => {
      const copyObj = Object.create(response);
      const fieldType = copyObj.fields[key].dataType;
      if (fieldType in formMapDefineData) {
        copyObj.fields[key]['component'] = formMapDefineData[fieldType].component;
        copyObj.fields[key]['props'] = this.makeProps(
          formMapDefineData[fieldType].props,
          copyObj.fields[key],
          curentData
        );
        // レイアウト情報をセットする
        if (key in copyObj.field_layout) {
          copyObj.fields[key] = { ...copyObj.fields[key], ...copyObj.field_layout[key] };
        }
        result.push(copyObj.fields[key]);
      }
    });
    result.push({ display_id: 'output_format' });
    result.push({ display_id: 'preview' });
    return result as Array<FieldChildrenProp>;
  }

  /**
   * APIから取得した「datatype」をみてフロントで使用する
   * コンポーネント情報（各コンポーネント毎に渡す値）をマッピングする
   * @param props - コンポーネントに渡すべきプロパティー
   * @param fieldInfo - フィールド情報
   * @param curentData - アイテム情報
   * @returns コンポーネントProps { [k: string]: string }
   */
  public makeProps(props: string[], fieldInfo: FieldChildrenProp, curentData?: Array<FieldsInfo>) {
    const result: { [k: string]: any } = {};
    const fieldsData = fieldInfo as { [k: string]: any };
    props.forEach(prop => {
      if (prop in formMapPropsDefineData) {
        const setFieldKey = formMapPropsDefineData[prop];
        if (prop === 'selectData') {
          // select component に渡すoptionリストを加工
          const selectOptions: Array<{ [k: string]: string | number }> = fieldsData[setFieldKey];
          if (selectOptions === undefined) {
            return;
          }

          selectOptions
            .sort((a, b) => {
              return a.sort_id > b.sort_id ? 1 : -1;
            })
            .map(option => {
              option['text'] = option['value'];
              option['value'] = option['option_id'] ? option['option_id'] : option['o_id'];
              return option;
            });
          result[prop] = { name: fieldInfo['display_id'], options: selectOptions };
        } else {
          result[prop] = fieldsData[setFieldKey];
        }
      }
    });
    // 既存アイテム情報がある場合はデータセット
    if (curentData !== undefined) {
      const currentFieldData = curentData?.find(v => v.field_id === fieldInfo.display_id);
      result['value'] = currentFieldData?.value;
    }
    return result;
  }

  /**
   * フロントに描画する際のコンポーネント並び順をHexaBaseUIに合わせます
   * @param fieldList - フィールド情報
   * @returns 「row」「col」で指定したフィールド情報
   */
  protected getLayoutKeySort(fieldList: Array<FieldChildrenProp>) {
    if (fieldList.length === 0) return [];
    return fieldList.sort((a, b) => {
      if ('row' in a && 'col' in b) {
        if (
          typeof a['row'] === 'number' &&
          typeof a['col'] === 'number' &&
          typeof b['row'] === 'number' &&
          typeof b['col'] === 'number'
        ) {
          return a['row'] - b['row'] || a['col'] - b['col'];
        }
      }
      return 0;
    });
  }

  /**
   * フィールドに対して任意で指定したコンポーネント使用する場合の
   * 定義ファイルを読み取り使用コンポーネント情報に上書きをする
   * @param fields - フィールド情報
   * @param addFields - 任意コンポーネント定義オブジェクト
   * @returns フィールド情報に任意コンポーネント定義を上書きしたオブジェクト
   */
  public addOptionalFormField(
    fields: Array<FieldChildrenProp>,
    addFields: Array<{ [k: string]: any }>,
    isRegister?: boolean
  ) {
    return fields.map(field => {
      const matchField = addFields.find(v => v.display_id === field.display_id);
      // propsがObjectの為先にマージしておく
      if (matchField !== undefined && 'props' in matchField) {
        // 新規作成限定propsがある場合先にマージする
        if (matchField['registProps'] !== undefined && isRegister !== undefined && isRegister) {
          matchField['props'] = { ...matchField['props'], ...matchField['registProps'] };
        }
        matchField['props'] = { ...field['props'], ...matchField['props'] };
      }

      return matchField !== null ? { ...field, ...matchField } : field;
    });
  }

  /**
   * GetInputFields APIからの情報を確認し
   * アクション毎に「表示する要素か」「更新可能か」「必須項目か」
   * 判別するための情報をフィールド情報に追加
   * @param field - 単体フィールド情報
   * @param inputFields - GetInputFields APIから取得したアクション毎のフィールド情報
   */
  public inputRestriction(field: FieldChildrenProp, inputFields: GetInputFieldsActions) {
    if (field.field_id in inputFields.action_field_settings) {
      const fieldSetting = inputFields.action_field_settings[field.field_id];
      if ('props' in field && !fieldSetting.update) {
        // backendで更新不可に設定されている場合入力要素をdisableに変更
        field['props']!['isReadonly'] = true;
      } else {
        field['props']!['isReadonly'] = false;
      }
      field = {
        ...field,
        ...{
          show: fieldSetting.show,
          update: fieldSetting.update,
          mandatory: fieldSetting.mandatory
        }
      };
    }
    return field;
  }

  /**
   * GetInputFields APIからの情報を確認し
   * フィールド情報に初期値があった場合フィールドオブジェクトに追加する
   * @param field - 単体フィールド情報
   * @param inputFields - GetInputFields APIから取得したアクション毎のフィールド情報
   * @returns 単体フィールド情報
   */
  public setSelected(
    field: FieldChildrenProp,
    inputFields: GetInputFieldsActions
  ): FieldChildrenProp {
    if (field.field_id in inputFields.action_fields) {
      const fieldSetting = inputFields.action_fields[field.field_id];

      if (this.isExsist(fieldSetting.selected)) {
        if ('props' in field) {
          field['props']!['value'] = fieldSetting.selected;
        }
      }
    }
    return field;
  }

  /**
   * GetInputFields APIからの情報を確認し
   * 初期値があった場合登録用データを返します
   * @param field - 単体フィールド情報
   * @param inputFields - GetInputFields APIから取得したアクション毎のフィールド情報
   * @returns 登録用データ
   */
  public setRregistData(
    field: FieldChildrenProp,
    inputFields: GetInputFieldsActions
  ): { [k: string]: string } {
    const result: { [k: string]: string } = {};
    if (field.field_id in inputFields.action_fields) {
      const fieldSetting = inputFields.action_fields[field.field_id];

      if (this.isExsist(fieldSetting.selected)) {
        result[field.display_id] = fieldSetting.selected;
      }
    }
    return result;
  }

  /**
   * マスタコントロールコンポーネントに使用するColumnデータをセットします
   * セットしているデータの体裁（並び順）を整えます
   * @param data - コンポーネントに渡すマスタデータ
   * @param sortKey - マスターデータの並び替えを行う際のデータkey
   * @param masterName - 使用するマスタ名
   */
  public modifyMasterData(data: any, sortKey: string, masterName: string) {
    const result: { [k: string]: Array<{ [k: string]: string }> } = {};
    if (data !== undefined) {
      result['columns'] = masterName !== undefined ? masterControlColums[masterName] : [];
      result['body'] = this.createSelectType(data, sortKey);
    }
    return result;
  }

  /**
   * セレクトオプションの並び順を変更
   * @param array - セレクトオプション配列
   * @param sortKey - 並び替えkey値
   * @returns セレクトオプション配列
   */
  public createSelectType(
    array: Array<{ [k: string]: string }>,
    sortKey: string
  ): Array<{ [k: string]: string }> {
    if (array.length === 0) return [];
    return array.sort((a, b) => {
      return a[sortKey] !== undefined
        ? a[sortKey].toString() < b[sortKey].toString()
          ? -1
          : 1
        : 1;
    });
  }

  /**
   * 最大Grid数を取得するためのレコード取得
   * @param fieldsInfo - フィールド情報
   * @returns GridColumn数を一番使用している単体フィールド情報
   */
  public getMaxcolRecode(fieldsInfo: Array<FieldChildrenProp>) {
    const maxColRecode = fieldsInfo.reduce((a, b) => {
      if (
        typeof a.col === 'number' &&
        typeof b.col === 'number' &&
        typeof a.sizeX === 'number' &&
        typeof b.sizeX === 'number'
      ) {
        return a.col + a.sizeX > b.col + b.sizeX ? a : b;
      }
      return {} as FieldChildrenProp;
    });
    return maxColRecode;
  }

  /**
   * マスター参照系コンポーネントにマスターデータをセットする
   * @param field - フィールド情報
   * @param masterData - マスターデータ
   * @returns マスター情報をセットしたフィールド情報
   */
  addOptionalMasterData(
    field: FieldChildrenProp,
    masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } }
  ) {
    if ('dblookup' in field && field.dblookup !== undefined) {
      if (
        field.component === 'MasterSelectControl' ||
        field.component === 'MasterSelectSetControl' ||
        field.component === 'MasterSelectMultiControl' ||
        field.component === 'MasterSelectMultiSetControl'
      ) {
        field.props!['columns'] = masterData[field.dblookup]['columns'];
        field.props!['body'] = masterData[field.dblookup]['body'];
      } else if (field.component === 'SelectArea' || field.component === 'SelectComboBoxArea') {
        field.props!['selectData'] = {
          name: field.display_id,
          options: masterData[field.dblookup].body.map(v => {
            const text = this.modifyDisplayLabel(field, v);
            const dataKey = field.dataKeyColum === undefined ? '' : v[field.dataKeyColum];
            return { value: v.i_id, text: text, key: dataKey, i_id: v.i_id };
          })
        };
      }
    }
    if (!('dblookup' in field) && 'dslookup_info' in field && field.dslookup_info !== undefined) {
      if (
        field.component === 'MasterSelectControl' ||
        field.component === 'MasterSelectSetControl' ||
        field.component === 'MasterSelectMultiControl' ||
        field.component === 'MasterSelectMultiSetControl'
      ) {
        field.props = { ...field.props, ...{ columns: [], body: [] } };
        field.props.columns = masterData[field.dslookup_info.dslookup_datastore_id]
          ? masterData[field.dslookup_info.dslookup_datastore_id]['columns']
          : [];
        field.props.body = masterData[field.dslookup_info.dslookup_datastore_id]
          ? masterData[field.dslookup_info.dslookup_datastore_id]['body']
          : [];
        field.props.leftSideInputName = field.display_id;
        field.props.dialogTitle = `${field.name}選択`;
      }
    }
    return field;
  }

  /**
   * 特定のフィールド情報の画面表示項目を変更する場合この関数で書き換えます
   * @param field - フィールド情報
   * @param dblookupItem - DB参照先アイテム情報
   * @returns {string} プルダウン内テキスト
   */
  modifyDisplayLabel(field: FieldChildrenProp, dblookupItem: { [k: string]: string }) {
    let result = field.valueColum === undefined ? '' : dblookupItem[field.valueColum];
    // 局所処理の為定義ファイルには移動しない
    if (field.display_id === 'payment_information_id') {
      const closingMonth = '当月';
      const closingDay = dblookupItem.closing_day !== null ? dblookupItem.closing_day : '';
      const paymentMonth = dblookupItem.payment_month !== null ? dblookupItem.payment_month : '';
      const paymentDay = dblookupItem.payment_day !== null ? dblookupItem.payment_day : '';
      result = `${closingMonth}${closingDay} / ${paymentMonth}${paymentDay}`;
    }
    return result;
  }

  /**
   * コンポーネント間で選択肢によって
   * マスター情報を絞り込む際に使用（※セレクトのみ対応）
   * 例）品目3 -> 規格コンポーネント内マスタ絞込み表示
   * @param fieldData - フィールド情報
   * @param masterData - マスターデータ
   * @param inputRes - インプット要素
   * @param keyDisplayId - マスタを絞り込む元となる画面項目名
   * @param targetDisplayId - 絞り込む対象となる画面項目名
   * @param keyDisplayName - 指定したい名称カラム
   * @returns array - 絞込みを行ったフィールドデータ
   */
  public getNarrowDownSelectOptin(
    fieldData: Array<FieldChildrenProp>,
    masterData: { [k: string]: { [k: string]: Array<{ [k: string]: string }> } },
    inputRes: { [k: string]: string },
    keyDisplayId: string,
    targetDisplayId: string,
    keyDisplayName?: string
  ): Array<FieldChildrenProp> {
    if (inputRes.name === keyDisplayId) {
      let inputValue = inputRes.value;
      const deep = fieldData.findIndex(v => v.display_id === targetDisplayId);
      if (deep < 0) {
        return fieldData;
      }
      const initialField = this.addOptionalMasterData(fieldData[deep], masterData);
      const selectOptions: Array<{ [k: string]: string }> = initialField['props']!['selectData'][
        'options'
      ];

      const optionCount = selectOptions.length;
      // db 参照型による特別実装
      if (
        ((initialField.component === 'SelectArea' && initialField.dataType === 'dslookup') ||
          (initialField.component === 'SelectComboBoxArea' &&
            initialField.dblookup !== undefined)) &&
        initialField.originReference !== undefined
      ) {
        const findRecode = masterData[initialField.originReference].body.find(
          v => v.i_id === inputRes.value
        );
        //名称カラムの設定
        if (findRecode !== undefined) {
          if (keyDisplayName === undefined) {
            //名称カラム未設定の場合nameで設定
            inputValue = findRecode.name;
          } else {
            //名称カラム設定がある場合その値で設定
            inputValue = findRecode[keyDisplayName];
          }
        }
      }
      const filtedOption =
        inputRes.value !== undefined && inputRes.value.length !== 0
          ? selectOptions.filter(v => v.key === inputValue)
          : [];
      fieldData[deep]['props']!['selectData']['options'].splice(0, optionCount, ...filtedOption);
      fieldData[deep]['props']!['InputValue'] = '';
      fieldData[deep]['props']!['selectValue'] = '';
      fieldData[deep]['props']!['isDisabled'] =
        inputRes.value !== undefined && inputRes.value.length === 0 ? true : false;
    }
    return fieldData;
  }

  /**
   * マスタ参照型SelectAreaの登録初期値セット
   * @param {FieldChildrenProp} field - DBから取得した単一フィールド情報
   * @returns {FieldChildrenProp} - DBから取得した単一フィールド情報
   */
  public setSelectAreaItemId(field: FieldChildrenProp) {
    if (
      field.component === 'SelectArea' &&
      field.dataType === 'dslookup' &&
      field.props !== undefined &&
      field.props.value !== null &&
      typeof field.props.value === 'object' &&
      typeof field.props.value !== null &&
      field.props.value['item_id'] !== undefined
    ) {
      field.props.value = field.props.value['item_id'];
    }
    return field;
  }

  /**
   * マスタ参照型SelectAreaの登録初期値セット
   * @param {FieldChildrenProp} field - DBから取得した単一フィールド情報
   * @returns {FieldChildrenProp} - DBから取得した単一フィールド情報
   */
  public setPropsByLocalProperty(field: FieldChildrenProp, thisProperty: { [k: string]: any }) {
    if (field.props !== undefined) {
      const keys = Object.keys(thisProperty);
      for (const [key, value] of Object.entries(field.props)) {
        if (
          field.props[key] !== null &&
          typeof field.props[key] === 'object' &&
          'property' in field.props[key] &&
          field.props[key].property &&
          field.props[key].name !== undefined &&
          field.props[key].name !== null &&
          keys.includes(field.props[key].name)
        ) {
          const includeKey = field.props[key].name;

          field.props[key] = thisProperty[includeKey];
        }
      }
    }
    return field;
  }

  /**
   * 発注・納品明細情報入力欄の初期登録BOX生成
   * @param initialData - 明細入力フォームの初期設定
   * @returns array 明細入力フォーム設定値配列
   */
  public createInitialDetailBox(
    initialData: Array<FieldChildrenProp>
  ): Array<Array<FieldChildrenProp>> {
    const detailAreas: Array<Array<FieldChildrenProp>> = [];
    detailAreas.push(JSON.parse(JSON.stringify(initialData)));
    detailAreas.push(JSON.parse(JSON.stringify(initialData)));
    detailAreas.push(JSON.parse(JSON.stringify(initialData)));
    for (let i = 0; i < 3; i++) {
      detailAreas[i].forEach((val, index) => {
        if ('props' in detailAreas[i][index] && detailAreas[i][index]['props'] !== undefined) {
          detailAreas[i][index]['props']!['name'] = `${
            detailAreas[i][index]['props']!['name']
          }[${i}]`;
        }
      });
    }
    return detailAreas;
  }

  /**
   * Selectコンポーネントで使用できるように整形
   * @module setSelectItemArrayFormat
   * @param Array<any> items - 整形に使用するベースデータ
   * @param string findItemName - フィルタ時に使用するプロパティ
   * @param string setItemValue - 整形時にValue側に設定される値のプロパティ
   * @param string setItemText - 整形時にText側に設定される値のプロパティ
   * @return selectItemData - 整形したデータ
   */
  public setSelectItemArrayFormat(
    items: Array<any>,
    findItemName: string,
    setItemValue: string,
    setItemText: string
  ) {
    let ArrayData = [];
    const selectItemData: SelectProps = { name: '', options: [] };
    ArrayData = items.filter((v1, i1, a1) => {
      return a1.findIndex(v => v1[findItemName] === v[findItemName]) === i1;
    });

    for (const item of ArrayData) {
      if (
        item[setItemValue] &&
        typeof item[setItemValue] === 'string' &&
        item[setItemValue].length > 0
      ) {
        selectItemData.options.push({ value: item[setItemValue], text: item[setItemText] });
      }
    }
    return selectItemData;
  }

  /**
   * 編集画面にて登録されている明細データ分の
   * 明細情報入力フィールドを表示するための設定情報を加工
   * @param {any} currentDetailData - 登録されているデータ配列 {[k: string]: string}[]
   * @param {array} defaultData - 明細情報入力エリアの基本設定
   * @param {string} noteDisplayId - 備考欄のdisplay_id
   * @param {string} defaultButtonStr - 備考欄ボタンに表示する初期文言
   * @param {string} existenceButtonStr - 備考欄ボタンに表示する入力後文言
   * @returns {array} 明細情報入力エリアのに登録情報をマージした配列情報 Array<Array<FieldChildrenProp>>
   */
  public getCurrentDetailArea(
    currentDetailData: any,
    defaultData: Array<FieldChildrenProp>,
    noteDisplayId?: string,
    defaultButtonStr?: string,
    existenceButtonStr?: string
  ): Array<Array<FieldChildrenProp>> {
    const currentData = JSON.parse(JSON.stringify(currentDetailData));
    const currentItemData: Array<{ [k: string]: string }> = currentData['items'];
    if (currentItemData === undefined || currentItemData.length === 0) return [];
    const detailAreas: Array<Array<FieldChildrenProp | any>> = [];
    for (let i = 0; i < currentItemData.length; i++) {
      detailAreas.push(this.deepCopy(defaultData));
    }
    if (detailAreas.length === 0) return [];
    detailAreas.map((detail, i) => {
      detail.map(field => {
        field['props']!['name'] = `${field['props']!['name']}[${i}]`;
        if (currentItemData[i] && currentItemData[i][field['field_id']]) {
          field['props']!['value'] = currentItemData[i][field['field_id']];
        }
        return field;
      });
      detail.push({ i_id: currentItemData[i].i_id });
      if (noteDisplayId && defaultButtonStr && existenceButtonStr) {
        return this.changeNoteButtonLabel(
          detail,
          noteDisplayId,
          defaultButtonStr,
          existenceButtonStr
        );
      } else {
        return detail;
      }
    });
    return detailAreas;
  }

  /**
   * 明細の登録情報に消費税・金額計算し追加
   * @param {array} registData - DB登録用配列
   * @param {array} taxMaster - 課税マスタ
   * @returns {array} registData - 消費税・金額計算し追加したDB登録用配列
   */
  public setDetailsAmountProps(registData: Array<any>, taxMaster: Array<{ [k: string]: string }>) {
    const keyPrice = 'unit_price';
    const keyQuantity = 'order_quantity';
    const taxId = 'tax_class_id';
    const keyTax = 'tax';
    const KeyAmount = 'amount';
    registData.forEach((v, i) => {
      const price = v.item[keyPrice] ? Number(v.item[keyPrice]) : 0;
      const quantity = v.item[keyQuantity] ? Number(v.item[keyQuantity]) : 0;
      const taxClassId = v.item[taxId] ? v.item[taxId] : undefined;
      registData[i].item[KeyAmount] =
        Number(v.item[KeyAmount]) === price * quantity
          ? price * quantity
          : Number(v.item[KeyAmount]);
      const taxRate = this.getTax(taxClassId, taxMaster);
      if (registData[i].item[KeyAmount] > 0) {
        registData[i].item[keyTax] =
          Number(v.item[keyTax]) === this.setRounding(registData[i].item[KeyAmount] * taxRate)
            ? this.setRounding(registData[i].item[KeyAmount] * taxRate)
            : Number(v.item[keyTax]);
      }
    });
    return registData;
  }

  /**
   * 消費税率を計算しやすい形に変更
   * @param taxClassId - 課税マスタID
   * @param taxMaster - 課税マスタ
   */
  public getTax(taxClassId: string, taxMaster: Array<{ [k: string]: string }>): number {
    const taxRow = taxMaster.find(v => v.i_id === taxClassId);
    let taxRate = 0;
    if (taxRow && taxRow['tax_rate'] !== '0') {
      taxRate = Number(taxRow['tax_rate']) / 100;
    }
    return taxRate;
  }

  /**
   * 明細情報(Props経由)のトータル金額を計算します
   * 1明細分
   * @param {array} detailData - 明細1レコード分のProps配列
   * @readonly {number} - 数量・単価より計算した合計金額
   */
  public getAmountDetailRow(detailData: Array<FieldChildrenProp>): number {
    const unitPrice = detailData.find(v => v.display_id === 'unit_price')?.props?.value || 0;
    const orderQuantity =
      detailData.find(v => v.display_id === 'order_quantity')?.props?.value || 0;
    const totalPrice = Number(unitPrice) * Number(orderQuantity);
    return totalPrice;
  }

  /**
   * 特定明細(Props)の課税金額を算出
   * @param {array} detailData - 明細1レコード分のProps配列
   * @param {array} taxData - 課税マスタ
   * @param {number} price - 合計金額
   */
  public getTaxDetailRow(
    detailData: Array<FieldChildrenProp>,
    taxData: Array<{ [k: string]: string }>,
    price: number
  ): number {
    const totalPrice = price === undefined || typeof price !== 'number' ? 0 : price;
    const taxClassId = detailData.find(v => v.display_id === 'tax_class_id')?.props?.value;
    if (taxClassId) {
      const rate = this.getTax(taxClassId, taxData);
      return this.setRounding(totalPrice * rate);
    }
    return 0;
  }

  /**
   * 消費税調整用関数
   * @param {number} number - トータルページ金額
   */
  setRounding(number: number): number {
    return Math.floor(number);
  }

  /**
   * 登録・編集画面で使用する金額合計パネルに表示する金額を返却する
   * @param DetailData - DB登録用明細情報
   * @returns {object} - 計算した金額と消費税額
   */
  public modifyInitialSummary(DetailData: Array<{ [k: string]: any }>) {
    const result = { total: [0], tax: [0] };
    DetailData.forEach((recode, index) => {
      if (recode.item.amount) result['total'][index] = Number(recode.item.amount);
      if (recode.item.tax) result['tax'][index] = Number(recode.item.tax);
    });
    return result;
  }

  /**
   * Date型のフォーマット変更を行います
   * @param {string} date - 日付け文字列
   * @param {string} zone - タイムゾーン
   * @param {string} formmat - フォーマット
   * @returns string - フォーマットを変更した日付け文字列
   */
  public editDatetype(date: string, zone: string, formmat: string): string {
    if (!this.isCheckVal(date)) return '';
    const datetime = DateTime.fromISO(date).setZone(zone);
    return formmat.length === 0 ? datetime.toSQLDate() : datetime.toFormat(formmat);
  }

  public getGridStatus(data: Array<FieldChildrenProp>): string {
    const maxColRecode = this.getMaxcolRecode(data);
    const colCount = maxColRecode.col! + maxColRecode.sizeX!;
    const colWidth = 100 / colCount;
    return `${colWidth}% `.repeat(colCount);
  }

  /**
   * 備考欄項目のボタンラベル変更
   * @param {array} data - フィールド情報
   * @param {string} targetName - 書き換え対象フィールドのdisplay_id
   * @param {string} defaultStr - 表示するデフォルト文字列
   * @param {string} existenceStr - 表示変更文字列
   * @returns {array} data - フィールド情報
   */
  public changeNoteButtonLabel(
    data: Array<FieldChildrenProp>,
    targetName: string,
    defaultStr: string,
    existenceStr: string
  ) {
    const noteIndex = data.findIndex(v => v.display_id === targetName);
    if (noteIndex > -1) {
      const label = this.isCheckVal(data[noteIndex].props!.value) ? existenceStr : defaultStr;
      data[noteIndex]['props']!.buttonLabel = label;
    }
    return data;
  }

  /***
   * 以下エラー関連判別メソッド
   */

  /**
   * アイテム詳細APIのレスポンスデータの正当性を確認します
   * @param data {any} APIからのレスポンスデータ
   * @returns {boolean} true:問題なし
   */
  protected isItemDetailType(data: any): data is GetItemDetailsResponse {
    return (
      data !== null &&
      typeof data === 'object' &&
      typeof data.title === 'string' &&
      Array.isArray(data.field_values) &&
      (Array.isArray(data.status_list) || data.status_list === null) &&
      (Array.isArray(data.status_actions) || data.status_actions === null) &&
      Array.isArray(data.item_actions)
    );
  }

  /**
   * エラー種類に合ったエラーメッセージを整形
   * @param errCode
   * @returns { string } エラーメッセージ
   */
  protected makeException(errCode: string): string {
    const err = errorConf.find(v => v.code === errCode);
    return err !== null ? `[${err?.code}]${err?.message}` : '';
  }

  /**
   * 該当文字列がEmptyでないか確認
   * @param data - 確認対象文字列
   * @returns bool
   */
  isCheckVal(data: string): boolean {
    if (data !== null && data !== undefined && typeof data === 'string' && data.length > 0)
      return true;
    return false;
  }

  /**
   * 以下より検索関連メソッド
   */
  /**
   * 動的生成コンポーネントに渡すオブジェクト（設定）を生成
   * @returns 各フィールド情報を含んだ配列
   * [
   *  {
   *    as_title: true
   *    col: 0
   *    component: "InputArea"
   *    dataType: "text"
   *    display_id: "order_application_no"
   *    fieldIndex: 0
   *    field_id: "60050d23eb0c9000012d15ee"
   *    full_text: false
   *    max_value: ""
   *    min_value: ""
   *    name: "発注申請No"
   *    props: {__ob__: Observer}
   *    row: 0
   *    search: true
   *    show_list: true
   *    sizeX: 2
   *    sizeY: 1
   *    status: false
   *    title_order: 4
   *    unique: false
   *  }...
   *]
   */
  public createFieldsList(
    fieldsData: DatastoreFields,
    masterData: any,
    searchForm: any
  ): FieldChildrenProp[] {
    let result: FieldChildrenProp[] = [];
    // 各フィールド情報を取得し適用するモジュールコンポーネントとprops情報を追加
    Object.keys(fieldsData.fields).forEach(key => {
      const copyObj = Object.create(fieldsData);
      const fieldType = copyObj.fields[key].dataType;
      if (fieldType in formMapDefineData) {
        copyObj.fields[key]['component'] = formMapDefineData[fieldType].component;
        copyObj.fields[key]['props'] = this.makeProps(
          formMapDefineData[fieldType].props,
          copyObj.fields[key]
        );
        copyObj.fields[key]['show'] = true;
        result.push(copyObj.fields[key]);
      }
    });
    result = this.addOptionalFormField(result, this.deepCopy(searchForm)).map(field =>
      this.addOptionalMasterData(field, masterData)
    );

    return this.getLayoutKeySort(result.filter(f => f.col !== undefined));
  }

  /**
   * 検索用にステータスリストを取得
   * @param appId - アプリケーションID
   * @param dId - 対象データストアID
   * @returns {array} - ステータスリスト
   */
  public async getStatusList(appId: string, dId: string) {
    const data = await this.getItemSearchConditions(appId, dId);
    if (!data || !data.result) return [];
    return data.result.filter(v => {
      if (v.data_type === 'status') {
        return v.statuses === undefined
          ? []
          : v.statuses.map(recode => {
              recode['text'] = recode['status_name'];
              recode['value'] = recode['status_id'];
            });
      }
    });
  }

  /**
   * 検索パネル用に表示する要素を取得
   * HexaBaseで「検索条件に利用」にチェック(search:true)、
   * データレポートの検索条件(rpf_idを含む)で絞り込む
   * @param {DatastoreFields} fieldsData - HexaBaseフィールド情報
   * @param masterData - マスタ情報配列
   * @param searchForm - 任意コンポーネント定義
   * @returns Array<FieldChildrenProp> - フィールド情報を含んだ配列
   */
  public createSearchConf(
    fieldsData: DatastoreFields,
    masterData: any,
    searchForm: any
  ): Array<FieldChildrenProp> {
    const data = this.createFieldsList(fieldsData, masterData, searchForm);
    return data.length !== 0 ? data.filter(v => v.search === true || 'rpf_id' in v) : [];
  }

  /**
   * 検索パネルにて使用するSelect要素(ステータス選択用)にステータスリストを付与します
   * @param {Array<FieldChildrenProp>} data - フィールド情報を含んだ配列
   * @param {Array<{[k: string]: string}>} statuses - ステータスリスト
   * @returns {Array<FieldChildrenProp>} data - フィールド情報を含んだ配列
   */
  public setStatusBySelectProp(
    data: Array<FieldChildrenProp>,
    statuses: Array<{ [k: string]: string }>
  ): Array<FieldChildrenProp> {
    const StatusSelectInitialOption: { text: string; value: string[] } = {
      text: '全ステータス',
      value: []
    };
    if (data.length === 0 || statuses.length === 0) return [];
    return data.map(v => {
      if (v.dataType === 'status') {
        v.props!['selectData'] = { options: [] };
        // ステータス検索の場合全ステータスを検索できるOptionを用意
        for (const index in statuses)
          StatusSelectInitialOption.value.push(statuses[index].status_id);
        v.props!['selectData']['options'].push(StatusSelectInitialOption, ...statuses);
      }
      return v;
    });
  }

  /**
   * 自動生成の入力要素のデータ型を取得します
   * @param {Array<FieldChildrenProp>} searchFieldsData - フィールド情報を含んだ配列
   * @returns {[k: string]: string} - {要素のdisplay_id: 要素のdata型}
   */
  public getTypes(searchFieldsData: Array<FieldChildrenProp>) {
    const fieldsType: { [k: string]: string } = {};
    for (const i in searchFieldsData) {
      fieldsType[searchFieldsData[i].display_id] = searchFieldsData[i].dataType;
    }
    return fieldsType;
  }

  public createColumns(columnList: Array<{ [k: string]: string | number }>, fields: any) {
    if (columnList.length === 0 || Object.keys(fields).length === 0) return [];
    Object.keys(fields).forEach(key => {
      const findIndex = columnList.findIndex(v => v.name === fields[key]['display_id']);
      if (findIndex > -1) {
        columnList[findIndex] = {
          ...{
            text: fields[key]['name'] ? fields[key]['name'] : fields[key]['title'],
            value: fields[key]['display_id']
          },
          ...columnList[findIndex]
        };
      }
    });
    return columnList.sort((a, b) => {
      return a.order < b.order ? -1 : 1;
    });
  }

  /**
   * 検索APIで取得した要素のトータルページ数を取得します
   * @param totalItems - 合計アイテム数
   * @param perPage - 1ページ辺りのアイテム数
   * @returns number - トータルページ数
   */
  public getTotalPage(totalItems: number, perPage: number): number {
    return Math.ceil(totalItems / perPage);
  }

  /**
   * 一括処理ポップアップにカラム情報を渡す際に
   * 特定カラム(Status・i_id)を除去します
   * @param {Array<{[k: string]: string}>} colums - カラム情報
   * @returns {Array<{[k: string]: string}>} colums - カラム情報
   */
  public modifyPopupColums(
    colums: Array<{ [k: string]: string | number }>
  ): Array<{ [k: string]: string | number }> {
    return colums.filter(v => v.data_type !== 'status' && v.name !== 'i_id');
  }

  /**
   * 入力値を値渡しで返却
   * @param {any} data - コピー元
   * @returns deepcopy
   */
  public deepCopy(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  /**
   * ステータスボタンリストの加工
   * 各ボタンの活性・非活性等を定義ファイルと付け合わせる
   * @param {string} userId - 社員マスタ内のログインユーザーのアイテムID
   * @param {Array<StatusActions>} statusActionList - ステータスアクションリスト
   * @param {string} buttonDefine - ボタンルール定義
   * @param {Array<FieldsInfo>} itemData - アイテム詳細データ
   * @returns {Array} アクションリスト
   */
  public createStatusButtonList(
    userId: string,
    statusActionList: Array<StatusActions>,
    buttonDefine: { [k: string]: any },
    itemData?: Array<FieldsInfo>
  ): Array<StatusActions> {
    if (buttonDefine === undefined) return statusActionList;
    return this.updateStatusActionProps(userId, statusActionList, buttonDefine, itemData);
  }

  /**
   * 承認関連ボタンの押下制御をリストに追加します
   * @param {string} userId - 社員マスタ内のログインユーザーのアイテムID
   * @param {Array<StatusActions>} statusActionList - ステータスアクションリスト
   * @param {[k: string]: any} defineList - ボタンルール定義
   * @param {Array<FieldsInfo> | undefined} itemData - アイテム詳細データ
   * @returns {Array} アクションリスト
   */
  public updateStatusActionProps(
    userId: string,
    statusActionList: Array<StatusActions>,
    defineList: { [k: string]: any },
    itemData?: Array<FieldsInfo>
  ): Array<StatusActions> {
    if (statusActionList.length === 0) return [];
    return statusActionList.map(action => {
      if (
        defineList[action.display_id] !== undefined &&
        defineList[action.display_id].disabledRule !== undefined
      ) {
        const disabledRule = defineList[action.display_id].disabledRule;
        switch (disabledRule.rule) {
          case 'userItemId': {
            const targetField = disabledRule.fieldId;
            action['isDisable'] =
              itemData !== undefined && this.isApplicant(userId, targetField, itemData);
            break;
          }
          case 'initial':
            action['isDisable'] = true;
            break;
          default:
            break;
        }
      }
      return action;
    });
  }

  /**
   * ログインユーザーが発注申請者か確認
   * @param {string} userId - 社員マスタ内のログインユーザーのアイテムID
   * @param {string} fieldId - 確認対象のフィールドID
   * @param {Array<FieldsInfo>} itemData - アイテム詳細情報
   * @returns {boolean} - 発注申請者ならtrue
   */
  public isApplicant(userId: string, fieldId: string, itemData: Array<FieldsInfo>): boolean {
    const targetData = itemData.find(field => field.field_id === fieldId);
    if (targetData !== undefined) {
      if (targetData.value === null || targetData.value === undefined) return false;
      if (targetData.value.item_id === userId) return true;
    }
    return false;
  }

  /**
   * 実行可能なアクションを算出
   * statusIDとアクションIDの接頭語の命名規則にてアクションを取得する
   * 例）
   * アイテムステータス：order_preparing
   * 取得できるアクション：order_preparing_edit、order_preparing_new 等
   * 「order_preparing_edit」が含まれるもの
   * @param {Array<StatusActions>} statusAction - ステータスアクション
   * @param {Array<ItemActions>} itemActions - データ操作アクション
   * @param {string} allowActionPrefix - 実行を許可するアクションのプレフィックス値（inculudesで判断）
   * @returns アクションボタンリスト
   */
  public getExecAllowAction(
    statusAction: Array<StatusActions>,
    itemActions: Array<ItemActions>,
    allowActionPrefix: string
  ): Array<ActionButtons> {
    if (allowActionPrefix === undefined) return [];
    if (allowActionPrefix) {
      const result = [...statusAction, ...itemActions].filter(action => {
        return action.display_id !== undefined && action.display_id.includes(allowActionPrefix);
      });
      return result as Array<ActionButtons>;
    } else {
      // allowActionPrefixが空で指定された場合は全てのアクションを返す
      const result = [...statusAction, ...itemActions];
      return result as Array<ActionButtons>;
    }
  }

  /**
   * アクションボタンのProps（disable、color）を変更します
   * @param {Array<ActionButtons>} actions - アクションボタンリスト
   * @returns {Array<ActionButtons> | []} - アクションボタンリスト
   */
  public modifyButtonProp(actions: Array<ActionButtons>, type?: string) {
    if (actions.length === 0) return [];
    type = type === undefined ? '' : type;
    return actions.map(action => {
      action['active'] = action.display_id === type ? true : false;
      action['isDisable'] = false;
      if (type?.length === 0) {
        action['isDisable'] = false;
      } else if (action.display_id !== type) {
        action['isDisable'] = true;
      }
      return action;
    });
  }

  /**
   * API毎にエラーメッセージを判別し取得します
   * エラーが存在した場合は例外を投げます
   * @param {string} - api APIの種別
   * @param {any|string} - response APIの返り値又はエラーコード
   * @returns void | Exception
   */
  public apiErrorHandle(api: string, response: any): void {
    if (this.isExsist(response)) {
      const errMessage = this.getErrMessage(api, response);
      if (errMessage.length > 0) {
        throw errMessage;
      }
    }
  }

  /**
   * API毎にエラーメッセージを判別し取得します
   * HITしない場合はシステムエラーを返却
   * @param {string} - api APIの種別
   * @param {any|string} - response APIの返り値又はエラーコード
   * @returns
   */
  public getErrMessage(api: string, response: any | string): string {
    if (typeof response === 'string') {
      return this.getMessage(api, response);
    }

    let result = '';
    switch (api) {
      case 'EXECUTEACTION': {
        if (this.isExsist(response.error_code)) {
          result = this.getMessage(api, response.error_code);
        }
        if (
          response.error !== undefined &&
          typeof response.error === 'object' &&
          response.error[0]
        ) {
          result = this.getMessage(api, response.error[0].error_code);
        }
        break;
      }
      case 'CREATEITEM': {
        if (this.isExsist(response.error_code)) {
          result = this.getMessage(api, response.error_code);
        }
        if (
          response.error !== undefined &&
          response.error !== null &&
          typeof response.error === 'object' &&
          response.error[0]
        ) {
          result = this.getMessage(api, response.error[0].error_code);
        }
        break;
      }
      case 'GETDATASTOREFIELDS': {
        if (this.isExsist(response.error)) {
          result = this.getMessage(api, response.error);
        }
        break;
      }
      case 'GETITEMSEARCHCONDITIONS': {
        if (this.isExsist(response.errors[0].error)) {
          result = this.getMessage(api, response.errors[0].error);
        }
        break;
      }
      case 'ITEMLIST': {
        if (this.isExsist(response.error_code)) {
          result = this.getMessage(api, response.error_code);
        }
        if (this.isExsist(response.errors) && this.isExsist(response.errors[0].error_code)) {
          result = this.getMessage(api, response.errors[0].error_code);
        }
        break;
      }
      case 'GETREPORTDATABYCONDITIONS': {
        let code = '';
        if (response.errors !== undefined && response.errors[0].error_code !== undefined) {
          code = response.errors[0].error_code;
        }
        if (response.error_code !== undefined) {
          code = response.error_code;
        }
        result = this.getMessage(api, code);
        break;
      }
      default: {
        break;
      }
    }

    if (
      result.length === 0 &&
      response.error !== undefined &&
      typeof response.error === 'string' &&
      response.error.length > 0 &&
      response.status !== 200
    ) {
      result = this.getDefaultErrorMessage();
    }

    return result;
  }

  /**
   * エラーリストよりエラーメッセージを取得します
   * HITしない場合はシステムエラーを返却
   * @param {string} - api APIの種別
   * @param {any|string} - code エラーコード
   * @returns
   */
  public getMessage(api: string, code: string) {
    const define = ErrorCode[api];
    return code in define ? define[code] : ErrorCode.DEFAULT.SYSTEM_ERROR;
  }

  /**
   * デフォルトのエラーメッセージを取得します
   * @returns string
   */
  public getDefaultErrorMessage() {
    return this.getErrMessage('DEFAULT', DefaultErrorCode);
  }

  /**
   * 3桁カンマの追加
   * @param {string} str
   * @returns string
   */
  public setComma(str: number | string): string {
    if (typeof str === 'string') str = Number(str);
    return str.toLocaleString();
  }

  /**
   * 3桁カンマを取り除く
   * @param {string} str
   * @returns string
   */
  public removeComma(str: string): string {
    if (typeof str !== 'string') return str;
    return str.replace(',', '');
  }

  /**
   * 対象の存在確認
   * @param {string | number} str
   * @returns boolean
   */
  public isExsist(str: string | number): boolean {
    return str !== undefined && str !== null;
  }

  /**
   * アクションパネルに表示するボタンを制限します
   * 命名規則でプロセス名が先頭にない場合、
   * アクションボタンには表示させないアクションとなります
   * @param actions - アクションボタンリスト
   * @param process - 処理区分
   * @returns
   */
  public filterDisplayButton(actions: Array<StatusActions>, process: string) {
    const result = actions.filter(action => {
      const regex = new RegExp(`^${process}`);
      return regex.test(action.display_id);
    });
    return result;
  }

  /**
   * フィールド情報にバリデーションルールを付与します
   * 各詳細画面で使用
   * @param fields {Array<FieldChildrenProp>} - field情報
   * @param fieldSetting {{ [k:string]: ActionFieldSetting}} - HexaBase側フィールド設定
   * @param type {string} - アクションタイプ
   * @param statusRules - 定義ファイルルール
   * @returns {Array<FieldChildrenProp>} - field情報
   */
  addInputRules(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting },
    type: string,
    statusRules: { [k: string]: Rules[] }
  ): Array<FieldChildrenProp> {
    return fields.map(field => {
      if (field.props === undefined) return field;

      const ruleArray = [];

      // Hexa側設定必須項目
      if (fieldSetting[field.field_id] !== undefined) {
        // 必須項目確認
        if (fieldSetting[field.field_id].mandatory) {
          const requiredName = field.component === 'SelectArea' ? 'selectRequired' : 'required';
          ruleArray.push(requiredName);
        }
      }
      // アプリ側設定項目
      if (statusRules !== undefined && statusRules[type] !== undefined) {
        const row = statusRules[type].find(val => val.id === field.display_id);
        if (row !== undefined) {
          ruleArray.push(row.rule);
        }
      }

      if (field.props!['rules'] === undefined) {
        field.props!['rules'] = '';
      }
      if (ruleArray.length > 0) field.props!['rules'] = ruleArray.join('|');
      return field;
    });
  }

  /**
   * 各フィールド情報に入力制限を付与します
   * 各詳細画面で使用
   * @param fields {Array<FieldChildrenProp>} - field情報
   * @param fieldSetting
   * @returns {Array<FieldChildrenProp>} - field情報
   */
  modifyInputAllow(
    fields: Array<FieldChildrenProp>,
    fieldSetting: { [k: string]: ActionFieldSetting }
  ): Array<FieldChildrenProp> {
    return fields.map(field => {
      if (fieldSetting[field.field_id] !== undefined) {
        const row = fieldSetting[field.field_id];
        if (row.update) {
          field.props! = { ...field.props!, ...{ isDisabled: false, isReadonly: false } };
        }
        if (field.dataType === 'dslookup') {
          field.props!.isBtnVisible = true;
          field.props!.isLeftSideInputReadOnly = false;
        }
        field = {
          ...field,
          ...{
            show: row.show,
            update: row.update,
            mandatory: row.mandatory
          }
        };
      }
      return field;
    });
  }

  /**
   * 他フィールドとの紐付け条件がある際にコンポーネント毎のルールをセットする
   * 詳細画面 - 初回表示時
   * @param rules - バリデーションルール
   * @param regisItem - 登録済アイテム情報
   * @param basicFieldsData - フィールドデータ
   * @returns {key: number, rules: string}[] - {key: フィールド情報のインデックス, rules: 適応ルール}
   */
  addLinkingRuleByInitial(
    rules: Rules[],
    regisItem: any,
    basicFieldsData: Array<FieldChildrenProp>
  ): { key: number; rules: string }[] {
    if (rules.length === 0) return [];

    const result: { key: number; rules: string }[] = [];
    for (const i in rules) {
      const addRuleComponentIndex = basicFieldsData.findIndex(
        field => field.display_id === rules[i].id
      );
      if (rules[i].referenceField !== undefined) {
        const temp = rules[i].referenceField!;
        const ruleList: string[] = [];

        if (rules[i].rule !== undefined && rules[i].rule.length > 0) {
          ruleList.push(...rules[i].rule);
        }
        // ルール生成
        for (const refIndex in temp) {
          const ruleTargetId = temp[refIndex].id;
          const rule = temp[refIndex].rule;
          const isAdd = temp[refIndex].addValue;

          // 値の紐付きは無いためルールのみ追加
          if (!isAdd) {
            if (this.isExsist(regisItem[ruleTargetId])) {
              ruleList.push(rule);
            }
            continue;
          }

          const targetFieldIndex = basicFieldsData.findIndex(
            field => field.display_id === ruleTargetId
          );
          if (targetFieldIndex > -1) {
            const ruleTargetVal = regisItem[ruleTargetId] ? regisItem[ruleTargetId] : '';
            const value =
              basicFieldsData[targetFieldIndex].dataType === 'datetime'
                ? this.editDatetype(ruleTargetVal, 'Asia/Tokyo', 'yyyy-MM-dd')
                : ruleTargetVal;
            ruleList.push(`${rule}:${value},${basicFieldsData[targetFieldIndex].name}`);
          }
        }

        if (basicFieldsData[addRuleComponentIndex].props !== undefined) {
          result.push({ key: addRuleComponentIndex, rules: ruleList.join('|') });
        }
      }
    }
    return result;
  }

  /**
   * 他フィールドとの紐付け条件がある際にコンポーネント毎のルールをセットする
   * 詳細画面 - 入力時
   * @param rules - バリデーションルール
   * @param response - inputデータ
   * @param basicFieldsData - フィールドデータ
   * @returns {key: number, rules: string}[] - {key: フィールド情報のインデックス, rules: 適応ルール}
   */
  public addLinkingRuleByInput(
    rules: Rules[],
    response: any,
    basicFieldsData: Array<FieldChildrenProp>
  ): { key: number; rules: string }[] {
    const result: { key: number; rules: string }[] = [];
    let row: { [k: string]: any } = {};
    for (const i in rules) {
      if (rules[i].referenceField !== undefined) {
        const temp = rules[i].referenceField!;
        const find = temp.find(data => data.id === response.name);

        if (find !== undefined) row = { ...rules[i] };
        if (Object.keys(row).length > 0) {
          const targetIndex = basicFieldsData.findIndex(field => field.display_id === row.id);
          if (targetIndex > -1) {
            const ruleArray = this.deepCopy(row.referenceField);
            if (row.referenceField.length > 0) {
              for (const index in ruleArray) {
                const targetName = basicFieldsData.find(v => ruleArray[index].id === v.display_id)!
                  .name;
                if (ruleArray[index].id === response.name) {
                  ruleArray[index] = ruleArray[index].addValue
                    ? `${ruleArray[index].rule}:${response.value},${targetName}`
                    : ruleArray[index].rule;
                } else if (
                  basicFieldsData.find(v => ruleArray[index].id === v.display_id)!.props!.value
                ) {
                  const field = basicFieldsData.find(v => ruleArray[index].id === v.display_id);
                  if (field !== undefined) {
                    const value =
                      field.dataType === 'datetime'
                        ? this.editDatetype(field.props!.value, 'Asia/Tokyo', 'yyyy-MM-dd')
                        : field.props!.value;
                    ruleArray[index] = ruleArray[index].addValue
                      ? `${ruleArray[index].rule}:${value},${targetName}`
                      : ruleArray[index].rule;
                  }
                }
              }
              for (const index in ruleArray) {
                if (typeof ruleArray[index] === 'object') {
                  ruleArray.splice(index, 1);
                }
              }

              const ruleDefine = rules.find(rule => rule.id === response.name);
              if (
                ruleDefine !== undefined &&
                ruleDefine.rule !== undefined &&
                ruleDefine.rule.length > 0
              ) {
                ruleArray.push(...ruleDefine.rule);
              }
            }
            result.push({ key: targetIndex, rules: ruleArray.join('|') });
          }
        }
      }
    }
    return result;
  }
  /*
   * 指定したアクションを取得します
   * @param actions - アクションリスト
   * @param searchActionId - 対象のアクションID
   * @returns アクションオブジェクト
   */
  public getFormDisplayAction(actions: Array<ItemActions>, searchActionId: string) {
    const find = actions.find(action => action.display_id === searchActionId);
    if (find === undefined) {
      throw `${searchActionId} 見つかりませんでした`;
    }
    return actions.find(action => action.display_id === searchActionId);
  }

  /**
   * アイテムリクエストがあった際
   * エラー等確認をし、問題があった場合例外を発生させます
   * @param response - ItemDetailAPIレスポンス
   * @param excludeList - ステータス判定を行う場合の除外ステータスリスト
   */
  checkInitialItem(response: GetItemDetailsResponse, excludeList?: string[]): void {
    // ステータスの確認
    // 除外ステータスリストがあったらステータス判定を行う
    if (excludeList !== undefined && excludeList.length > 0) {
      const status = response.field_values.find(v => v.dataType === 'status');
      if (status !== undefined) {
        const currentStatus = response.status_list.find(v => v.status_id === status.value);
        if (
          currentStatus &&
          currentStatus.display_id &&
          excludeList.includes(currentStatus!.display_id)
        ) {
          throw new ItemStatusError();
        }
      }
    }
  }

  /**
   * ファイルダウンロード
   * @param data - データ
   * @param downLoadFileName - ファイル名
   * @param downLoadFormat - ファイル形式
   * @param info - ファイル情報
   * @return {void} - なし
   */
   public formOutPutDownLoad(data:any, downLoadFileName?: string, downLoadFormat?: string, info?:any): void {
    const blob = data;
    const type = downLoadFormat ? contentType[downLoadFormat] : info.contentType;
    const downLoadName = downLoadFileName ? `${downLoadFileName}.${downLoadFormat}` : info.name;

    const url = window.URL.createObjectURL(
      new Blob([blob], {
        type: type
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', downLoadName);
    document.body.appendChild(link);
    link.click();
  }

  /**
   * ステート内の管理マスタより指定の定義を取得します
   * 取得できなかった場合、null又は設定したデフォルト値を返却します
   * @param define - 定義名
   * @param process - プロセス名
   * @param defalut - デフォルト設定値
   * @returns 定義文
   */
  public getOption(
    define: string,
    process: string,
    display: string,
    defalut?: string | number
  ): string | number | null {
    if (appState.options !== undefined && Object.keys(appState.options).length) {
      if (
        appState.options[process][display] !== undefined &&
        appState.options[process][display][define] !== undefined
      ) {
        return appState.options[process][display][define];
      }

      if (appState.options[process][define] !== undefined) {
        return appState.options[process][define];
      }
    }

    return defalut === undefined ? null : defalut;
  }

  /**
   * アイテムリスト表示件数を取得
   * @param process - プロセス名
   * @param defaultPerPage - デフォルト設定値
   * @returns 定義文
   */
  getPerPage(process: string, display: string, defaultPerPage: number) {
    return this.getOption('LIST_PER_PAGE', process, display, defaultPerPage) as number;
  }

  /**
   * アイテムリストのタイムアウトを取得
   * @param process - プロセス名
   * @param defaultPerPage - デフォルト設定値
   * @returns 定義文
   */
  getDataTimeOut(process: string, display: string, defaultDataTimeOut: number) {
    return this.getOption(
      'LIST_DATA_RESULT_TIMEOUT',
      process,
      display,
      defaultDataTimeOut
    ) as number;
  }

  /**
   * アイテムリスト件数取得のタイムアウトを取得
   * @param process - プロセス名
   * @param defaultPerPage - デフォルト設定値
   * @returns 定義文
   */
  getTotalCountTimeOut(process: string, display: string, defaultDataTimeOut: number) {
    return this.getOption(
      'LIST_TOTAL_COUNT_TIMEOUT',
      process,
      display,
      defaultDataTimeOut
    ) as number;
  }

  /**
   * 帳票マスタよりレコードを取得
   * @param projectId - アプリID
   * @param formOutputDsId - データストアID
   * @param search  - 検索利用シーン
   * @returns アイテム一覧
   */
  public async getFormMaster(projectId: string, formOutputDsId: string, search: string) {
    const fieldList = await this.getItemSearchConditions(projectId, formOutputDsId);
    const active = fieldList.result
      .map(field => {
        let result: undefined | string = undefined;
        if (field.options !== undefined && field.display_id === 'is_active') {
          const temp = field.options.find(v => v.value === 'true');
          if (temp !== undefined) {
            result = temp.option_id;
          }
        }
        return result;
      })
      .filter(v => v);

    const condition: SearchConditions[] = [];
    const values: string[] = [search, active[0] !== undefined ? active[0] : ''];

    for (const index in initialSearchCondition) {
      condition.push({
        id: initialSearchCondition[index],
        search_value: [values[index]],
        exact_match: true
      });
    }

    const payload = {
      conditions: condition,
      page: 1,
      per_page: 0,
      use_display_id: true
    };
    return await this.getItemSearch(projectId, formOutputDsId, payload);
  }

  /**
   * 帳票出力ダイアログフィールドを作成する
   * @param registData - アイテムデータ
   * @param search - 利用シーン
   * @param projectId - アプリID
   * @param formOutputDsId - 帳票マスタID
   * @param optionalOutPutForm - 自動生成入力要素
   * @param basicFieldsData - 既存アイテムフィールドデータ
   * @returns
   */
  public async outputFormDialogFieldData(
    registData: any,
    search: string,
    projectId: string,
    formOutputDsId: string,
    optionalOutPutForm: { [k: string]: any }[],
    basicFieldsData: Array<FieldChildrenProp>
  ) {
    const result = await this.getFormMaster(projectId, formOutputDsId, search);
    const dbFields = await this.getFieldsData(projectId, formOutputDsId);

    const modifyFields = this.getFormOutputDialogFields(
      this.biludFields(dbFields),
      optionalOutPutForm,
      result
    );

    return modifyFields;
  }

  /**
   * 帳票モーダルへ表示する要素を調整します
   * @param fields - 入力要素prop
   * @param optionalOutPutForm - 入力要素の上書きオブジェクト
   * @param itemResponse - 帳票マスタのアイテムリスト
   * @returns 入力要素prop配列
   */
  public getFormOutputDialogFields(
    fields: FieldChildrenProp[],
    optionalOutPutForm: { [k: string]: any },
    itemResponse: GetItemsResponse
  ) {
    return this.addOptionalFormField(
      fields.filter(v => v.dataType !== 'status'),
      this.deepCopy(optionalOutPutForm)
    ).map(field => {
      if (field.display_id === inputNames.type) {
        field.props!['selectData'] = {
          options: this.createformTypeOptions(
            field.props!['selectData'].options,
            itemResponse.items
          )
        };
      }
      if (field.display_id === inputNames.templateName) {
        field.props!['selectData'] = {
          options: this.createTemplateOptions(itemResponse.items)
        };
      }
      return field;
    });
  }

  /**
   * 作成帳票の絞込み用valueを付与します
   * @param options
   * @param items - 帳票マスタのアイテムリスト
   * @returns
   */
  public createformTypeOptions(
    options: { [k: string]: string | boolean | number }[],
    items: { [k: string]: any }[]
  ) {
    if (items.length === 0 || options.length === 0) return [];
    return options
      .map(option => {
        // 出力形式の対応状況を確認
        const allowType: {pdf?: boolean, excel?: boolean} = {};
        items.forEach(item => {
          if (item.form_type === option.text) {
            const allow = this.getFormat(item);
            // 各アイテムが「pdf」「excel」に対応しているか確認
            if ('pdf' in allow && allow.pdf) allowType.pdf = true;
            if ('excel' in allow && allow.excel) allowType.excel = true;
          }
        });
        const find = items.find(item => item.form_type === option.text);

        // 作成帳票の絞り込み
        if (find !== undefined) {
          return { ...option, ...allowType };
        }

        return undefined;
      })
      .filter(v => v);
  }

  /**
   * テンプレート名の絞込み用valueを付与します
   * @param items - 帳票マスタのアイテムリスト
   * @returns
   */
  public createTemplateOptions(items: { [k: string]: any }[]) {
    if (items.length === 0) return [];
    return items.map(item => {
      return {
        text: item.name,
        value: item.template_name,
        ftype: item.form_type,
        ...this.getFormat(item)
      };
    });
  }
  private getFormat(item: { [k: string]: any }) {
    const result: { [k: string]: boolean } = {};
    const checkFields = Object.keys(item).filter(v => v.includes(formFormatReplaceStr));
    for (const index in checkFields) {
      const fieldName = checkFields[index];
      // hexabase より文字列で返却される
      if (item[fieldName] === 'true') {
        result[fieldName.split('_')[1]] = true;
      }
    }
    return result;
  }

  public findMaster(master: { [k: string]: any }[], id: string) {
    return master.find(data => data.i_id === id);
  }

  /**
   * 帳票プレビュー用のSrc属性を生成し返却します
   * @param registData - 登録データ
   * @param data - 帳票フォーム入力データ
   * @param payload - 帳票リクエストPayload
   * @returns
   */
  public async getPreviewImgList(
    registData: any,
    data: { [k: string]: any },
    payload: { [k: string]: any }
  ) {
    const srcList: string[] = [];
    const selectTemplates: string[] = data.template_name.data.map(
      (val: { [k: string]: string }) => val.value
    );

    const response = await Promise.all(
      selectTemplates.map(async template => {
        return await this.execDocurain(template, payload, 'png');
      })
    );

    for (const index in response) {
      const blob = new Blob([response[index].data], { type: 'image/png' });
      const blob_url = window.URL.createObjectURL(blob);
      srcList.push(blob_url);
    }

    return srcList;
  }

  /**
   * ドキュレインに帳票出力リクエストを送る
   * @param templateList - 出力するテンプレート
   * @param data - 出力データ
   * @param format - 出力フォーマット
   */
  public async execDocurain(template: string, data: any, format: string) {
    return await this.docurain.createForm(template, data, format);
  }

  /**
   * ドキュレインに帳票出力リクエストを送る
   * @param templateList - 出力するテンプレート（配列）
   * @param data - 出力データ
   * @param fileName - 出力時ファイル名
   * @param format - 出力フォーマット
   * @returns void ファイルダウンロード
   */
  public async getForm(templateList: string[], data: any, fileName: string, format: string) {
    const requestFormat = mapFormat[format] !== undefined ? mapFormat[format] : format;

    for (const index in templateList) {
      const result = await this.docurain.createForm(templateList[index], data, requestFormat);
      this.formOutPutDownload(result.data, `${fileName}_${index}`, requestFormat);
    }
  }

  /**
   * ファイルダウンロード
   * @return {void} - なし
   */
  public formOutPutDownload(
    data: any,
    downLoadFileName?: string,
    downLoadFormat?: string,
    info?: any
  ): void {
    const blob = data;
    const type = downLoadFormat ? contentType[downLoadFormat] : info.contentType;
    const downloadName = downLoadFileName ? `${downLoadFileName}.${downLoadFormat}` : info.name;

    const url = window.URL.createObjectURL(
      new Blob([blob], {
        type: type
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', downloadName);
    document.body.appendChild(link);
    link.click();
  }

  /**
   * 帳票出力モーダルのルール追加
   * @param rules
   * @param registData
   * @param modifyFields
   * @returns
   */
  public outPutFormDialogAddFieldRule(
    rules: OutPutFormRules[],
    registData: any,
    modifyFields: FieldChildrenProp[]
  ) {
    rules.forEach(ruleList => {
      let rules: string[] = [];
      for (const [id, rule] of Object.entries(ruleList.target)) {
        rules = [...rules, `${rule}:${registData.item[id]},${ruleList.label[id]}`];
      }
      const findFieldIndex = modifyFields.findIndex(field => field.display_id === ruleList.id);
      if (findFieldIndex > -1) modifyFields[findFieldIndex].props!.rules = rules.join('|');
    });
    return modifyFields;
  }

  /**
   * ファイルidを指定しファイルをダウンロードします
   * @param {string} file_id - ファイルID
   * @returns APIレスポンス
   */
  public getFile(file_id: string) {
    return this.hexaApi
      .getFile(authState.token, file_id)
      .then(response => {
        return response;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /**
   * アップロードしたファイルをhexabaseにいアップします
   * @param {string} itemId  - 紐付けるアイテムID
   * @param {string} fieldId - 紐付けるフィールドID
   * @param {FILE} formData  - アップロードされたフォームデータ
   * @returns APIレスポンス
   */
  public uploadFile(itemId: string, fieldId: string, formData: FormData) {
    return this.hexaApi
      .uploadFile(authState.token, itemId, fieldId, formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /**
   * 指定したアイテムを削除します
   * @param {string} itemId  - 紐付けられたアイテムID
   * @param {string} fieldId - 紐付けられたフィールドID
   * @param {string} fileId  - 紐付けられたファイルID
   * @returns APIレスポンス
   */
  public deleteFile(itemId: string, fieldId: string, fileId: string) {
    return this.hexaApi
      .deleteFile(authState.token, itemId, fieldId, fileId)
      .then(response => {
        return response;
      })
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        throw new Error(error.message);
      });
  }

  /**
   * 入力ルールに対してマスターの値を付与したものを返却します
   * @param row {InputRules} - 入力ルール
   * @param options {{[k: string]: any}} - store経由で取得したマスタ情報
   * @returns {InputRules}
   */
  public getRuleByMaster(row: InputRules, options: { [proccess: string]: { [key: string]: any } }) {
    if ('referenceMasterOptions' in row) {
      const dataByMaster = row.referenceMasterOptions!;
      for (const index in dataByMaster) {
        const matchIndex = row.rule.findIndex(v => v === dataByMaster[index].rule);
        if (matchIndex > -1) {
          const level = dataByMaster[index].define.split('.').filter(v => v);
          let tmpObject: { [k: string]: any } = {};
          let define: number | string = '';
          for (const i in level) {
            if (level[i] in options && typeof options[level[i]] === 'object') {
              tmpObject = options[level[i]];
            } else if (typeof tmpObject[level[i]] === 'object') {
              tmpObject = tmpObject[level[i]];
            } else if (
              typeof tmpObject[level[i]] === 'string' ||
              typeof tmpObject[level[i]] === 'number'
            ) {
              define = tmpObject[level[i]];
            }
          }
          row.rule[matchIndex] = `${row.rule[matchIndex]}:${define}`;
        }
      }
    }
    return row;
  }

  /**
   * Compornentに付与するプロパティを絞り込みます
   * 元のform定義propsの一部を流用したい際に使用
   * 例) 一括処理で使用するファイルアップロード等
   * @param defineProps {{[k: string]: any}} - props情報
   * @param getProps {string[]} - 取り出したいプロパティ
   * @returns {[k: string]: any} | {}
   */
  public getNarrowingProps(defineProps: { [k: string]: any }, getProps: string[]) {
    if (Object.keys(defineProps).length === 0) return {};
    for (const [key, value] of Object.entries(defineProps)) {
      if (!getProps.includes(key)) {
        delete defineProps[key];
      }
    }
    return defineProps;
  }

  /**
   * 取得したワークブック情報をjson形式に整形する
   * @param resultData - FileReadしたExcelファイルデータ
   * @returns JSON整形前の中間配列データ
   */
  fixdata(resultData: any) {
    let o = '',
      l = 0;
    const w = 10240;
    for (; l < resultData.byteLength / w; ++l)
      o += String.fromCharCode.apply(
        null,
        new Uint8Array(resultData.slice(l * w, l * w + w)) as any
      );
    o += String.fromCharCode.apply(null, new Uint8Array(resultData.slice(l * w)) as any);
    return o;
  }

  /**
   * 指定したワークブック情報をjson形式に整形する
   * @param workbook - ワークブック情報
   * @param X - XLSXライブラリ
   * @returns result
   */
  to_json(workbook: any, X: any) {
    const result = {} as any;
    workbook.SheetNames.forEach(function(sheetName: string) {
      const roa = X.utils.sheet_to_json(workbook.Sheets[sheetName], {
        raw: true
      });
      if (roa.length > 0) {
        result[sheetName] = roa;
      }
    });
    return result;
  }

  /**
   * 引数のCSVデータを連想配列に変換する
   * @param csv FileReadしたCSVデータ
   * @returns key: value形式でCSV項目とを持つ配列を返す
   */
  convertCsvToArray(csv: any) {
    //文字列をバイト列に変換
    const sjisArray = new Uint8Array(csv);
    //encode.jsを使い、文字コードを自動判別しUNICODEへ変換
    const unicodeArray = Encoding.convert(sjisArray, {
      to: 'UNICODE',
      from: 'AUTO',
      type: 'string'
    });
    //header:CSV1行目の項目 :csvRows:項目に対する値
    const [header, ...csvRows] = unicodeArray
      .split(/\r?\n/g)
      .filter(function(row: string) {
        if (row !== '') {
          return row;
        }
      })
      .map((row: string) => {
        return this.csvSplit(row);
      });

    const uniqueHeader = new Set(header);
    if (uniqueHeader.size !== header.length) {
      throw 'ヘッダ項目名が重複しているため処理を中止します';
    }

    let arrayInKeyAndValue;

    const tmpResultArray = csvRows.map(function(r: { [x: string]: any }) {
      arrayInKeyAndValue = header.map(function(_: any, index: number) {
        //ヘッダーの空白文字を削除。keyとvalueに値をセット
        return { key: header[index].replace(/\s+/g, ''), value: r[index] };
      });
      arrayInKeyAndValue = arrayInKeyAndValue.reduce(function(
        previous: { [x: string]: any },
        current: { key: string | number; value: any }
      ) {
        //{key: "物", value: "MacBook", メーカー: "apple", 値段: "3000"}を作成
        previous[current.key] = current.value;
        return previous;
      },
      {});
      return arrayInKeyAndValue;
    });
    return tmpResultArray;
  }

  /**
   * CSVの一行を引数に指定すると、
   * ""で囲まれた内部のコンマを分割せずに、
   * コンマで分割して配列の戻り値を返す関数
   * @param {string} line 文字列
   * @return {Array<string>} 配列
   */
  csvSplit(line: string) {
    let c = '';
    let s = new String();
    const data = [];
    let singleQuoteFlg = false;

    for (let i = 0; i < line.length; i++) {
      c = line.charAt(i);
      if (c === ',' && !singleQuoteFlg) {
        data.push(s.toString());
        s = '';
      } else if (c === ',' && singleQuoteFlg) {
        s = s + c;
      } else if (c === '"') {
        singleQuoteFlg = !singleQuoteFlg;
      } else {
        s = s + c;
        if (i === line.length - 1) {
          data.push(s.toString());
        }
      }
    }
    return data;
  }

  /**
   * 和暦 => 西暦への変換
   * @param {string} warekiYear 文字列 平成元年4月4日
   * @return {string} 西暦年
   */
  warekiToSeireki(warekiYear: string): number | null {
    const matches = warekiYear.match('^(明治|大正|昭和|平成|令和)([元0-9０-９]+)年');
    if (matches) {
      const eraName = matches[1];
      let year = parseInt(
        matches[2].replace(/[元０-９]/g, function(match) {
          if (match === '元') {
            return '1';
          }
          return String.fromCharCode(match.charCodeAt(0) - 65248);
        })
      );
      year = Number(year);
      if (eraName === '明治') {
        year += 1867;
      } else if (eraName === '大正') {
        year += 1911;
      } else if (eraName === '昭和') {
        year += 1925;
      } else if (eraName === '平成') {
        year += 1988;
      } else if (eraName === '令和') {
        year += 2018;
      }
      return year;
    }
    return null;
  }

  /**
   * パスワードリセット申請、パスワード再設定リクエストを送る
   * @param {{[k: string]: string}} params - リクエストパラメーター
   * @param {string} method APIリクエストメソッド post=パスワードリセット申請 put=パスワード再設定
   * @returns
   */
  public sendRequestPassword(params: { [k: string]: string }, method = 'post') {
    return this.hexaApi
      .passwordOperation(params, method)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * ログイン後のパスワード再設定リクエストを送る
   * @param {{[k: string]: string}} params - リクエストパラメーター
   * @param {string} method APIリクエストメソッド post=パスワードリセット申請 put=パスワード再設定
   * @returns
   */
  public changePassword(params: { [k: string]: string }) {
    return this.hexaApi
      .setPassword(authState.token, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * 指定されたファイルIDのファイルをダウンロード
   * @module getFileDownload
   * @param {string} fileId - ダウンロードするファイルID
   * @param {string} fileName - 使用するアイテムID
   * @param {string} downloadType - 使用するアイテムID
   * @return response - hexabaseAPIのdeleteFileレスポンス
   */
  public getFileDownload(fileId: string, fileName: string, downloadType: string) {
    return this.hexaApi
      .getFile(authState.token, fileId)
      .then(response => {
        if (response) {
          const blob = response;
          const url = window.URL.createObjectURL(
            new Blob([blob], {
              type: downloadType // 例：'application/pdf'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          return response;
        } else {
          throw new Error(this.makeException(this.typeDifferentCode));
        }
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * アイテムリンクを追加する
   * @param applicationId
   * @param datastoreId
   * @param itemId
   * @param linkDatastoreId
   * @param linkItemId
   * @returns
   */
  public addItemLinked(
    applicationId: string,
    datastoreId: string,
    itemId: string,
    linkDatastoreId: string,
    linkItemId: string
  ) {
    const linkData = {
      link_datastore_id: linkDatastoreId,
      link_item_id: linkItemId
    };
    return this.hexaApi
      .addItemLink(authState.token, applicationId, datastoreId, itemId, linkData)
      .catch(error => {
        hexabaseState.setApiStatus(error.response.status);
        return {
          ...error.response.data,
          ...{
            status: error.response.status,
            statusText: error.response.statusText
          }
        };
      });
  }

  /**
   * グループ情報取得
   * @param groupId :string //group-id は省略可。省略すると、TOPグループの情報を返す。
   * @returns
   */
  public getGroup(groupId?: string) {
    return this.hexaApi
      .getGroup(authState.token, groupId)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * 指定されたグループのユーザー一覧の取得
   * @param groupId :string //グループID
   * @param recursive :bool //グループ階層の下をたどって、所属するすべてのユーザーを取得します
   * @returns
   */
   public getUsersInGroup(groupId: string, recursive?: boolean) {
    return this.hexaApi
      .getUsersInGroup(authState.token, groupId, recursive)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * グループツリー情報取得
   * @returns
   */
   public getGroupTree() {
    return this.hexaApi
      .getGroupTree(authState.token)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * グループに新規ユーザーを作成
   * @param params
   * {
   *   "email": "グループに追加したいemailアドレス", //必須
   *   "g_id": "グループを識別するID", //必須
   *   "w_id": "ワークスペースのID",
   *   "username": "グループに追加したいユーザー名"
   * }
   * @returns
   */
   public addUser(params: any) {
    return this.hexaApi
      .createUser(authState.token, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

  /**
   * グループからユーザーを削除
   * @param params
   * {
   *   "g_id": "グループを識別するID", //必須
   *   "u_id": "ユーザーを識別するID", //必須
   *   "w_id": "ワークスペースを識別するID"
   * }
   * @returns
   */
   public removeUser(params: any) {
    return this.hexaApi
      .deleteUsers(authState.token, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw new Error(error.message);
      });
  }

}
